import {TextValue} from '../../../model/text-value';
import {VehiclePosition} from '../vehicle-position';
import {VehicleWarnings} from '../vehicle-warnings';
import {LatestActivityEntry} from '../../../model/tacho/table/latest-activity.entry';
import {MapSettings} from '../../../model/map.constants';
import {ConnectedTrailerEntry} from './connected-trailer.entry';
import {VehicleNoteStatusEntry} from './vehicle-note-status.entry';
import {DueService} from './due-service';
import {TaskType} from '../../../../pages/client/tasks/tasks-manager/model/task-type';
import {DispatcherTaskItem} from '../../dispatcher/dispatcher-task-item';
import {Label} from '../../../../pages/client/settings/global-config/labels-configs/model/Label';

export class LatestVehicleEntry {

  public id: number;
  public deviceKey: string;
  public plateNumber: string;
  public status: string;
  public warnings: VehicleWarnings;
  public hasDvr: boolean;
  public dvrModel: string;
  public hasGPSDevice: boolean;
  public hasTacho: boolean;
  public hasTab: boolean;
  public deactivated: boolean;
  public canRestartDVR: boolean;
  public lastDVRTime: string;
  public driver: TextValue<string>;
  public driverSimNo: string;
  public driverId: number;
  public secondDriver: TextValue<string>;
  public latestActivity: LatestActivityEntry;
  public time: string;
  public tabTime: string;
  public lastPingOn: string;
  public address: string;
  public position: VehiclePosition;
  public direction: number;
  public speed: number;
  public fuel: number;
  public fuelUsage: number;
  public adblueLevelPercent: number;
  public float1Liters: number;
  public float2Liters: number;
  public engineRpm: number;
  public axleWeight: number;
  public axleWeight2: number;
  public distance: number;
  public gpsOdometer: number;
  public distanceToday: number;
  public engineHours: number;
  public externalPowerV: number;
  public batteryLevel: number;
  public hasFreezer: boolean;

  public executingTask: boolean;
  public unconfirmedTask: boolean;
  public labels: Label[];
  public taskCount: number;
  public taskDistance: number;
  public fuelWarning: boolean;
  public taskTypes: TaskType[];
  public dispatcherItems: DispatcherTaskItem[];

  public setFreezerTemperature: number;
  public supplyFreezerTemperature: number;
  public actualFreezerTemperature: number;
  public freezerEngineOn: boolean;
  public freezerMode: string;

  public secondCompartmentSetPoint: number;
  public secondCompartmentSupplyTemperature: number;
  public secondCompartmentTemperature: number;
  public secondCompartmentIsOn: boolean;
  public secondCompartmentFreezerMode: string;

  public firstSensorTemperature: number;
  public secondSensorTemperature: number;
  public thirdSensorTemperature: number;
  public fourthSensorTemperature: number;

  public freezerPowerMode: string;
  public freezerRunMode: string;

  public freezerDieselHours: number;
  public freezerElectricHours: number;
  public ambientTemperature: number;
  public freezerStandbyHours: number;
  public gpsDeviceSimNo: string;
  public connectedTrailer: ConnectedTrailerEntry;
  public connectedVehicle: number;
  public engineTemperature: number;
  public serviceInRed: boolean;
  public hasElectricLock: boolean;
  // todo implement keyboardLock
  // public keyboardLocked: boolean;
  public electricLockStatus: string;
  public dueServices: DueService[];
  public trailer: boolean;
  public noteStatuses: VehicleNoteStatusEntry[] = [];
  public vehicleType: string;
  public fuelTankCapacity: number;
  public adBlueTankCapacity: number;
  public grossCombinationVehicleWeight: number;
  public workStartedAt: string;
  public trackByTab: boolean;
  public shellCardId: number;
  public tapa: boolean;
  public engineBlockControl: boolean;
  public doorsControl: boolean;
  public showTellTale: boolean;

  constructor(item: any) {
    this.id = item.id;
    this.deviceKey = item.deviceKey;
    this.plateNumber = item.plateNumber;
    this.status = item.status;
    this.setupWarnings(item);
    this.hasDvr = item.hasDvr;
    this.dvrModel = item.dvrModel;
    this.hasGPSDevice = item.hasGPSDevice;
    this.hasTacho = item.hasTacho;
    this.hasTab = item.hasTab;
    this.deactivated = item.deactivated;
    this.canRestartDVR = item.canRestartDVR;
    this.lastDVRTime = item.lastDVRTime;
    this.driver = LatestVehicleEntry.setupDriver(item.driver);
    this.secondDriver = LatestVehicleEntry.setupDriver(item.secondDriver);
    this.driverId = item.driverId;
    this.setupLatestActivity(item);
    this.time = item.time;
    this.tabTime = item.tabTime;
    this.lastPingOn = item.lastPingOn;
    this.address = item.address;
    this.position = item.position
      ? new VehiclePosition(Number(item.position.latitude), Number(item.position.longitude))
      : null;
    this.direction = item.direction;
    this.speed = item.speed;
    this.fuel = item.fuel;
    this.fuelUsage = item.fuelUsage;
    this.adblueLevelPercent = item.adblueLevelPercent;
    this.float1Liters = item.float1Liters;
    this.float2Liters = item.float2Liters;
    this.engineRpm = item.engineRpm;
    this.axleWeight = item.axleWeight;
    this.axleWeight2 = item.axleWeight2
    this.distance = item.distance;
    this.gpsOdometer = item.gpsOdometer;
    this.distanceToday = item.distanceToday;
    this.engineHours = item.engineHours;
    this.externalPowerV = item.externalPowerV;
    this.batteryLevel = item.batteryLevel;
    this.hasFreezer = item.hasFreezer;

    this.executingTask = item.executingTask;
    this.unconfirmedTask = item.unconfirmedTask;
    this.labels = item.labels;
    this.taskCount = item.taskCount;
    this.taskDistance = item.taskDistance;
    this.fuelWarning = item.fuelWarning;
    this.taskTypes = item.taskTypes != null ? item.taskTypes : [];
    this.dispatcherItems = item.dispatcherItems != null ? item.dispatcherItems : [];

    this.setFreezerTemperature = item.setFreezerTemperature;
    this.supplyFreezerTemperature = item.supplyFreezerTemperature;
    this.actualFreezerTemperature = item.actualFreezerTemperature;
    this.freezerEngineOn = item.freezerEngineOn;
    this.freezerMode = item.freezerMode;

    this.secondCompartmentSetPoint = item.secondCompartmentSetPoint;
    this.secondCompartmentSupplyTemperature = item.secondCompartmentSupplyTemperature;
    this.secondCompartmentTemperature = item.secondCompartmentTemperature;
    this.secondCompartmentIsOn = item.secondCompartmentIsOn;
    this.secondCompartmentFreezerMode = item.secondCompartmentFreezerMode;

    this.firstSensorTemperature = item.firstSensorTemperature;
    this.secondSensorTemperature = item.secondSensorTemperature;
    this.thirdSensorTemperature = item.thirdSensorTemperature;
    this.fourthSensorTemperature = item.fourthSensorTemperature;

    this.freezerPowerMode = item.freezerPowerMode;
    this.freezerRunMode = item.freezerRunMode;
    this.ambientTemperature = item.ambientTemperature;
    this.freezerDieselHours = item.freezerDieselHours;
    this.freezerElectricHours = item.freezerElectricHours;
    this.freezerStandbyHours = item.freezerStandbyHours;
    this.driverSimNo = item.driverSimNo;
    this.gpsDeviceSimNo = item.gpsDeviceSimNo;
    this.hasElectricLock = item.hasElectricLock;
    this.electricLockStatus = item.electricLockStatus;
    this.trailer = item.trailer;
    // todo implement keyboardLock
    // this.keyboardLocked = item.keyboardLocked;
    if (item.connectedTrailer != null) {
      this.connectedTrailer = new ConnectedTrailerEntry(item.connectedTrailer);
    }
    this.connectedVehicle = item.connectedVehicle;
    this.engineTemperature = item.engineTemperature;
    this.serviceInRed = item.serviceInRed;
    this.dueServices = item.dueServices;
    this.noteStatuses = item.noteStatuses != null ? item.noteStatuses : [];
    this.vehicleType = item.vehicleType;
    this.fuelTankCapacity = item.fuelTankCapacity;
    this.adBlueTankCapacity = item.adBlueTankCapacity;
    this.grossCombinationVehicleWeight = item.grossCombinationVehicleWeight;
    this.trackByTab = item.trackByTab;
    this.shellCardId = item.shellCardId;
    this.tapa = item.tapa;
    this.engineBlockControl = item.engineBlockControl;
    this.doorsControl = item.doorsControl;
    this.showTellTale = item.showTellTale;
  }

  public getIcon() {
    return MapSettings.ICONS['truck'][this.status] + '#rotate_' + this.direction;
  }

  public hasPosition(): boolean {
    return this.position != null;
  }

  private setupWarnings(item: any) {
    if (item.warnings) {
      this.warnings = new VehicleWarnings(
        Boolean(item.warnings.gpsOffline),
        Boolean(item.warnings.dvrOffline),
        Boolean(item.warnings.tabOffline),
        item.warnings.alarms
      );
    }
  }

  private static setupDriver(driver: any): TextValue<string> {
    return driver !== null ? new TextValue(driver.text, driver.value) : null;
  }

  private setupLatestActivity(item: any) {
    if (item.latestActivity) {
      this.latestActivity = new LatestActivityEntry(
        item.latestActivity.type,
        item.latestActivity.latestActivityStart,
        item.latestActivity.latestActivityDuration,
        item.latestActivity.driveBeforeBreak,
        item.latestActivity.drivingLeft,
        item.latestActivity.nextDayRest
      );
    }
  }
}

export enum Status {
  MOVING, NOT_MOVING, WARNING, OFFLINE, ENGINE_ON, IDLE, LOADING_UNLOADING_DELAY
}

export enum DVRModel {
  OLD_DVR, NEW_DVR
}
