import {Module, Role} from '../../../core/model/profile';
import {environment} from '../../../../environments/environment';
export const menuItems = [
  {
    title: 'menu.monitor',
    routerLink: '/monitor',
    icon: 'fa-television',
    selected: false,
    active: false,
    expanded: false,
    order: 0,
    roles: [
      Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
      Role.ROLE_CLIENT_EMPLOYEE_MANAGER,
      Role.ROLE_CLIENT_ENGINEER,
      Role.ROLE_PARTNER_EMPLOYEE,
      Role.ROLE_CLIENT_SENIOR_MANAGER
    ],
  },
  {
    title: 'menu.carrier-vehicles',
    routerLink: '/carrier-vehicles',
    icon: 'fa-truck',
    selected: false,
    active: false,
    expanded: false,
    order: 0,
    roles: [Role.ROLE_PARTNER_EMPLOYEE],
  },
  {
    title: 'menu.fleet.title',
    routerLink: '/fleet',
    icon: 'fa-truck',
    selected: false,
    active: false,
    expanded: false,
    order: 1,
    roles: [
      Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
      Role.ROLE_CLIENT_EMPLOYEE_MANAGER,
      Role.ROLE_CLIENT_ENGINEER,
      Role.ROLE_CLIENT_SENIOR_MANAGER],
    subMenu: [
      {
        active: false,
        title: 'menu.fleet.vehicles',
        routerLink: '/vehicles',
        icon: 'ico-truck'
      },
      {
        active: false,
        title: 'menu.fleet.vehicles-group',
        routerLink: '/groups',
        icon: 'ico-group'
      },
      {
        active: false,
        title: 'menu.fleet.track',
        routerLink: '/vehicles/track',
        icon: 'fa-road'
      },
      {
        active: false,
        title: 'menu.fleet.integrations',
        routerLink: '/vehicles/integrations',
        icon: 'fa-puzzle-piece'
      },
      {
        active: false,
        title: 'menu.fleet.detached-tablets',
        routerLink: '/vehicles/detached-tablets',
        icon: 'fa-tablet',
        roles: [
          Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
          Role.ROLE_CLIENT_ENGINEER
        ],
      }
    ]
  },
  {
    title: 'menu.employees.title',
    routerLink: '/coworkers',
    icon: 'fa-users',
    selected: false,
    active: false,
    expanded: false,
    order: 1,
    roles: [
      Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
      Role.ROLE_CLIENT_EMPLOYEE_MANAGER,
      Role.ROLE_CLIENT_ENGINEER,
      Role.ROLE_CLIENT_SENIOR_MANAGER],
    subMenu: [
      {
        active: false,
        title: 'menu.employees.clients-employees',
        routerLink: '/colleagues',
        icon: 'ico-users'
      },
      {
        active: false,
        title: 'menu.employees.department',
        routerLink: '/department',
        icon: 'fa-sitemap'
      },
      {
        active: false,
        title: 'menu.employees.drivers',
        routerLink: '/drivers',
        icon: 'ico-wheel'
      }
    ]
  },
  {
    title: 'menu.partners.title',
    routerLink: '/partner',
    icon: 'fa-briefcase',
    selected: false,
    active: false,
    expanded: false,
    order: 1,
    roles: [
      Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
      Role.ROLE_CLIENT_EMPLOYEE_MANAGER,
      Role.ROLE_CLIENT_ENGINEER,
      Role.ROLE_CLIENT_SENIOR_MANAGER],
    subMenu: [
      {
        active: false,
        title: 'menu.partners.partners',
        routerLink: '/partners',
        icon: 'fa-list'
      },
      {
        active: false,
        title: 'menu.partners.partners-employee',
        routerLink: '/partners-employees',
        icon: 'fa-address-card-o'
      },
      {
        active: false,
        title: 'menu.partners.partners-vehicles',
        routerLink: '/shared-vehicles',
        icon: 'ico-truck-head'
      },
      {
        active: false,
        title: 'menu.partners.partners-positions',
        routerLink: '/shared-positions',
        icon: 'ico-map-marker'
      },
      {
        active: false,
        title: 'menu.partners.partner-docs',
        routerLink: '/partners-docs',
        icon: 'fa-file-pdf-o',
        modules: [Module.ORDERS]
      }
    ]
  },
  {
    title: 'menu.alarms.title',
    routerLink: '/',
    icon: 'fa-exclamation-triangle',
    selected: false,
    active: false,
    expanded: false,
    order: 1,
    roles: [
      Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
      Role.ROLE_CLIENT_EMPLOYEE_MANAGER,
      Role.ROLE_CLIENT_ENGINEER,
      Role.ROLE_CLIENT_SENIOR_MANAGER
    ],
    modules: [Module.ALARM],
    subMenu: [
      {
        active: false,
        title: 'menu.alarms.alarms',
        routerLink: '/alarms',
        icon: 'fa-exclamation-triangle'
      },
      {
        active: false,
        title: 'menu.alarms.notices',
        routerLink: '/notices',
        icon: 'fa-exclamation-circle'
      },
      {
        active: false,
        title: 'menu.alarms.personal',
        routerLink: '/personal',
        icon: 'ico-alarm'
      }
    ]
  },
  {
    title: 'menu.reports.title',
    routerLink: '/reports',
    icon: 'fa-pie-chart',
    selected: false,
    active: false,
    expanded: false,
    order: 1,
    roles: [
      Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
      Role.ROLE_CLIENT_EMPLOYEE_MANAGER,
      Role.ROLE_CLIENT_ENGINEER,
      Role.ROLE_PARTNER_EMPLOYEE,
      Role.ROLE_CLIENT_SENIOR_MANAGER
    ],
    partnerModules: [Module.VIEW_REPORT],
    subMenu: [
      {
        active: false,
        title: 'menu.reports.accounting',
        icon: 'fa-book',
        modules: [Module.HEAVY_TRACK],
        hideInMobile: true,
        roles: [
          Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
          Role.ROLE_CLIENT_EMPLOYEE_MANAGER,
          Role.ROLE_CLIENT_ENGINEER,
          Role.ROLE_CLIENT_SENIOR_MANAGER
        ],
        subMenu: [
          {
            active: false,
            title: 'menu.reports.hours',
            routerLink: '/reports/hours',
            icon: 'fa-calendar',
          },
          {
            active: false,
            title: 'menu.reports.times',
            routerLink: '/reports/times',
            icon: 'fa-clock-o',
          },
          {
            active: false,
            title: 'menu.reports.country',
            routerLink: '/reports/country',
            icon: 'fa-globe',
          },
          {
            active: false,
            title: 'menu.reports.time-list',
            routerLink: '/reports/time-list',
            icon: 'fa-table',
          },
        ]
      },
      {
        active: false,
        title: 'menu.reports.driving',
        icon: 'ico-wheel',
        subMenu: [
          {
            active: false,
            title: 'menu.reports.period',
            routerLink: '/reports/period',
            icon: 'fa-clock-o'
          },
          {
            active: false,
            title: 'menu.reports.work',
            routerLink: '/reports/work',
            icon: 'fa-cogs'
          },
          {
            active: false,
            title: 'menu.reports.travel',
            routerLink: '/reports/travel',
            icon: 'fa-tachometer'
          }
        ]
      },
      {
        active: false,
        title: 'menu.reports.tacho.tacho',
        icon: 'fa-tachometer',
        modules: [Module.HEAVY_TRACK],
        roles: [
          Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
          Role.ROLE_CLIENT_EMPLOYEE_MANAGER,
          Role.ROLE_CLIENT_ENGINEER,
          Role.ROLE_CLIENT_SENIOR_MANAGER
        ],
        subMenu: [
          {
            active: false,
            title: 'menu.reports.tacho.charts',
            routerLink: '/reports/tacho-charts',
            icon: 'fa-bar-chart'
          },
          {
            active: false,
            title: 'menu.reports.tacho.tables',
            routerLink: '/reports/tacho-tables',
            icon: 'fa-table'
          },
          {
            active: false,
            title: 'menu.reports.tacho.latestWork',
            routerLink: '/reports/latest-work',
            icon: 'fa-car'
          },
          {
            active: false,
            title: 'menu.reports.tacho.countryHours',
            routerLink: '/reports/country-hours',
            icon: 'fa-clock-o',
            hideInMobile: true
          },
          {
            active: false,
            title: 'menu.reports.tacho.driverCountryHours',
            routerLink: '/reports/driver-country-hours',
            icon: 'fa-user',
            hideInMobile: true
          },
          {
            active: false,
            title: 'menu.reports.tacho.driverFaults',
            routerLink: '/reports/tacho-faults',
            icon: 'fa-exclamation-triangle',
            modules: [Module.TACHO_ANALYTICS],
            hideInMobile: true
          },
          {
            active: false,
            title: 'menu.reports.tacho.driversFaults',
            routerLink: '/reports/tacho-multi-faults',
            icon: 'fa-id-card',
            modules: [Module.TACHO_ANALYTICS],
            hideInMobile: true
          }
        ]
      },
      {
        active: false,
        title: 'menu.reports.eco',
        icon: 'ico-leaf',
        modules: [Module.HEAVY_TRACK],
        hideInMobile: true,
        roles: [
          Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
          Role.ROLE_CLIENT_EMPLOYEE_MANAGER,
          Role.ROLE_CLIENT_ENGINEER,
          Role.ROLE_CLIENT_SENIOR_MANAGER
        ],
        subMenu: [
          {
            active: false,
            title: 'menu.reports.eco-drivers',
            routerLink: '/reports/eco-drivers',
            icon: 'fa-id-card-o'
          },
          {
            active: false,
            title: 'menu.reports.eco-driver',
            routerLink: '/reports/eco-driver',
            icon: 'fa-user-circle'
          },
          {
            active: false,
            title: 'menu.reports.eco-vehicle',
            routerLink: '/reports/eco-vehicle',
            icon: 'fa-truck'
          },
          {
            active: false,
            title: 'menu.reports.eco-multi-vehicle',
            routerLink: '/reports/eco-multi-vehicle',
            icon: 'fa-car'
          },
          {
            active: false,
            title: 'menu.reports.eco-vehicle-group-configs',
            routerLink: '/reports/eco-vehicle-group-configs',
            icon: 'fa-sliders'
          }
        ]
      },
      {
        active: false,
        title: 'menu.reports.tasks',
        icon: 'fa-check',
        modules: [Module.MANAGEMENT],
        roles: [
          Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
          Role.ROLE_CLIENT_EMPLOYEE_MANAGER,
          Role.ROLE_CLIENT_ENGINEER,
          Role.ROLE_CLIENT_SENIOR_MANAGER
        ],
        subMenu: [
          {
            active: false,
            title: 'menu.reports.empty-kilometers',
            routerLink: '/reports/empty-kilometers',
            icon: 'fa-road',
            onlyForClients: environment.name === 'PREPROD' ? [450] : [6601, 6600, 6450]
          },
          {
            active: false,
            title: 'menu.reports.status-report',
            class: 'hidden-md-down',
            routerLink: '/reports/status-report',
            icon: 'fa-neuter',
          },
          {
            active: false,
            title: 'menu.reports.detailed-status',
            routerLink: '/reports/detailed-status',
            icon: 'fa-stack-overflow'
          },
          {
            active: false,
            title: 'menu.reports.taskReport',
            routerLink: '/reports/tasks-report',
            icon: 'fa-check-square-o'
          },
          {
            active: false,
            title: 'menu.reports.task-delay-report',
            routerLink: '/reports/task-delay',
            icon: 'fa-exchange'
          },
          {
            active: false,
            title: 'menu.reports.preliminary-route-report',
            routerLink: '/reports/preliminary-route',
            icon: 'ico-route',
            onlyForClients: [6601, 6600, 6450]
          },
          {
            active: false,
            title: 'menu.reports.trailer-swap-report',
            routerLink: '/reports/trailer-swap',
            icon: 'ico-trailer',
            modules: [Module.TRAILER_SWAP]
          }
        ]
      },
      {
        active: false,
        title: 'menu.reports.track',
        icon: 'fa-road',
        modules: [Module.HEAVY_TRACK],
        subMenu: [
          {
            active: false,
            title: 'menu.reports.locations',
            routerLink: '/reports/locations',
            icon: 'fa-map-pin'
          },
          {
            active: false,
            title: 'menu.reports.countryChange',
            routerLink: '/reports/country-change',
            icon: 'fa-flag-o'
          },
          // {
          //   title: 'menu.reports.tachoCountry',
          //   routerLink: '/reports/tacho-country',
          //   icon: 'fa-flag-checkered',
          //   modules: [Module.HEAVY_TRACK],
          //   roles: [Role.ROLE_CLIENT_EMPLOYEE_ADMIN],
          //   contractor: true
          // }
        ]
      },
      {
        active: false,
        title: 'menu.reports.freezer',
        routerLink: '/reports/freezer',
        icon: 'fa-snowflake-o',
        modules: [Module.HAS_FREEZERS],
        hideInMobile: true
      },
      {
        active: false,
        title: 'menu.reports.system',
        icon: 'fa-user-circle',
        roles: [
          Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
          Role.ROLE_CLIENT_EMPLOYEE_MANAGER,
          Role.ROLE_CLIENT_ENGINEER,
          Role.ROLE_CLIENT_SENIOR_MANAGER
        ],
        subMenu: [
          {
            active: false,
            title: 'menu.reports.video',
            routerLink: '/reports/video',
            icon: 'fa-file-video-o',
            modules: [Module.VIDEO]
          },
          {
            active: false,
            title: 'menu.reports.object-history',
            class: 'hidden-md-down',
            routerLink: '/reports/object-history',
            icon: 'fa-history',
            roles: [
              Role.ROLE_CLIENT_EMPLOYEE_MANAGER,
              Role.ROLE_CLIENT_ENGINEER,
              Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
              Role.ROLE_CLIENT_SENIOR_MANAGER
            ],
            modules: [Module.HEAVY_TRACK]
          },
          {
            active: false,
            title: 'menu.reports.orders-object-history',
            class: 'hidden-md-down',
            routerLink: '/reports/orders-object-history',
            icon: 'fa-history',
            roles: [
              Role.ROLE_CLIENT_EMPLOYEE_MANAGER,
              Role.ROLE_CLIENT_ENGINEER,
              Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
              Role.ROLE_CLIENT_SENIOR_MANAGER
            ],
            modules: [Module.ORDERS]
          }
        ]
      },
      {
        active: false,
        title: 'menu.reports.fuel',
        icon: 'ico-fuel',
        roles: [
          Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
          Role.ROLE_CLIENT_EMPLOYEE_MANAGER,
          Role.ROLE_CLIENT_ENGINEER,
          Role.ROLE_CLIENT_SENIOR_MANAGER
        ],
        subMenu: [
          {
            active: false,
            title: 'menu.reports.fuel-change',
            routerLink: '/reports/fuel/change',
            icon: 'fa-truck'
          },
          {
            active: false,
            title: 'menu.reports.fuel-change-by-country',
            routerLink: '/reports/fuel/change-by-country',
            icon: 'fa-globe'
          },
          {
            active: false,
            title: 'menu.reports.fuel-transactions',
            routerLink: '/reports/fuel/transactions',
            icon: 'fa-credit-card'
          },
        ]
      },
      {
        active: false,
        title: 'menu.reports.orders',
        routerLink: '/reports/orders',
        icon: 'ico-clipboard',
        modules: [Module.ORDERS],
        hideInMobile: true
      },
    ]
  },
  {
    title: 'menu.services.title',
    routerLink: '/service',
    icon: 'fa-wrench',
    selected: false,
    active: false,
    expanded: false,
    order: 1,
    roles: [
      Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
      Role.ROLE_CLIENT_ENGINEER,
      Role.ROLE_CLIENT_SENIOR_MANAGER,
      Role.ROLE_CLIENT_EMPLOYEE_MANAGER
    ],
    subMenu: [
      {
        active: false,
        title: 'menu.services.current',
        routerLink: '/service/current',
        icon: 'fa-bookmark',
        roles: [
          Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
          Role.ROLE_CLIENT_ENGINEER,
          Role.ROLE_CLIENT_SENIOR_MANAGER,
          Role.ROLE_CLIENT_EMPLOYEE_MANAGER
        ]
      },
      {
        active: false,
        title: 'menu.services.history',
        routerLink: '/service/history',
        icon: 'fa-check',
        roles: [
          Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
          Role.ROLE_CLIENT_ENGINEER,
          Role.ROLE_CLIENT_SENIOR_MANAGER,
          Role.ROLE_CLIENT_EMPLOYEE_MANAGER
        ],
      },
      {
        active: false,
        title: 'menu.services.service',
        routerLink: '/service/service',
        icon: 'fa-tags',
        roles: [
          Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
          Role.ROLE_CLIENT_ENGINEER,
          Role.ROLE_CLIENT_SENIOR_MANAGER,
          Role.ROLE_CLIENT_EMPLOYEE_MANAGER
        ],
      },
      {
        active: false,
        title: 'menu.services.report',
        routerLink: '/service/report',
        icon: 'fa-pie-chart',
        modules: [Module.HEAVY_TRACK],
        roles: [
          Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
          Role.ROLE_CLIENT_ENGINEER,
          Role.ROLE_CLIENT_SENIOR_MANAGER,
        ],
      },
      {
        active: false,
        title: 'menu.services.defects',
        routerLink: '/service/defects',
        icon: 'fa-gavel',
        modules: [Module.MANAGEMENT],
        roles: [
          Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
          Role.ROLE_CLIENT_ENGINEER,
          Role.ROLE_CLIENT_SENIOR_MANAGER,
        ],
      },
      {
        active: false,
        title: 'menu.services.inventory',
        routerLink: '/service/inventory',
        icon: 'fa-archive',
        modules: [Module.MANAGEMENT],
        roles: [
          Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
          Role.ROLE_CLIENT_ENGINEER,
          Role.ROLE_CLIENT_SENIOR_MANAGER,
          Role.ROLE_CLIENT_EMPLOYEE_MANAGER
        ],
      }
    ]
  },
  {
    title: 'menu.geo-zones.title',
    routerLink: '/geo-zones',
    class: 'hidden-md-down',
    icon: 'fa-map-o',
    selected: false,
    active: false,
    expanded: false,
    order: 1,
    roles: [
      Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
      Role.ROLE_CLIENT_EMPLOYEE_MANAGER,
      Role.ROLE_CLIENT_ENGINEER,
      Role.ROLE_CLIENT_SENIOR_MANAGER
    ],
    subMenu: [
      {
        active: false,
        title: 'menu.geo-zones.planner',
        routerLink: '/geo-zones/planner',
        icon: 'fa-arrows'
      },
      {
        active: false,
        title: 'menu.geo-zones.hits',
        routerLink: '/geo-zones/hits',
        icon: 'fa-check-circle-o'
      },
      {
        active: false,
        title: 'menu.geo-zones.stops',
        routerLink: '/geo-zones/stops',
        icon: 'fa-pause-circle-o'
      }
    ]
  },
  {
    title: 'menu.poi.title',
    routerLink: '/poi',
    icon: 'fa-map-marker',
    selected: false,
    active: false,
    expanded: false,
    order: 1,
    roles: [
      Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
      Role.ROLE_CLIENT_EMPLOYEE_MANAGER,
      Role.ROLE_CLIENT_ENGINEER,
      Role.ROLE_CLIENT_SENIOR_MANAGER
    ],
    modules: [Module.HEAVY_TRACK],
    subMenu: [
      {
        active: false,
        title: 'menu.poi.poi',
        routerLink: '/poi/poi',
        icon: 'fa-map-marker'
      },
      {
        active: false,
        title: 'menu.poi.group',
        routerLink: '/poi/group',
        icon: 'fa-map-pin'
      },
      {
        active: false,
        title: 'menu.marked-roads',
        routerLink: '/marked-roads',
        icon: 'fa-road'
      },
      {
        active: false,
        title: 'menu.poi.parkingBooking',
        routerLink: '/poi/parking-booking',
        icon: 'fa-product-hunt'
      }
    ]
  },
  {
    title: 'menu.documents.title',
    routerLink: '/documents',
    icon: 'fa-file-text-o',
    selected: false,
    active: false,
    expanded: false,
    order: 1,
    roles: [
      Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
      Role.ROLE_CLIENT_EMPLOYEE_MANAGER,
      Role.ROLE_CLIENT_ENGINEER,
      Role.ROLE_CLIENT_SENIOR_MANAGER
    ],
    modules: [Module.HEAVY_TRACK],
    subMenu: [
      {
        active: false,
        title: 'menu.documents.ddd',
        routerLink: '/documents/ddd',
        icon: 'fa-tachometer',
        class: 'hidden-sm-down',
        roles: [
          Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
          Role.ROLE_CLIENT_EMPLOYEE_MANAGER,
          Role.ROLE_CLIENT_ENGINEER,
          Role.ROLE_CLIENT_SENIOR_MANAGER
        ]
      },
      {
        active: false,
        title: 'menu.documents.forms',
        routerLink: '/documents/vehicle-change',
        icon: 'fa-check-square-o',
        roles: [
          Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
          Role.ROLE_CLIENT_EMPLOYEE_MANAGER,
          Role.ROLE_CLIENT_ENGINEER,
          Role.ROLE_CLIENT_SENIOR_MANAGER
        ]
      },
      {
        active: false,
        title: 'menu.documents.group-files',
        routerLink: '/documents/group-files',
        icon: 'fa-podcast',
        class: 'hidden-sm-down',
        roles: [
          Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
          Role.ROLE_CLIENT_EMPLOYEE_MANAGER,
          Role.ROLE_CLIENT_ENGINEER,
          Role.ROLE_CLIENT_SENIOR_MANAGER
        ]
      },
      {
        active: false,
        title: 'menu.documents.tripExpensesForms',
        icon: 'fa-suitcase',
        class: 'hidden-sm-down',
        roles: [
          Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
          Role.ROLE_CLIENT_EMPLOYEE_MANAGER,
          Role.ROLE_CLIENT_SENIOR_MANAGER
        ],
        subMenu: [
          {
            active: false,
            title: 'menu.documents.tripForms',
            routerLink: '/documents/trip-sheets',
            icon: 'fa-file-text-o'
          },
          {
            active: false,
            title: 'menu.documents.expensesForms',
            routerLink: '/documents/expenses-sheets',
            icon: 'fa-file-text-o'
          },
          {
            active: false,
            title: 'menu.documents.expenses',
            routerLink: '/documents/expenses',
            icon: 'fa-money'
          }
        ]
      },
      {
        active: false,
        title: 'menu.documents.freezerForm',
        routerLink: '/documents/freezer-form',
        icon: 'fa-snowflake-o',
        class: 'hidden-sm-down',
        roles: [
          Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
          Role.ROLE_CLIENT_EMPLOYEE_MANAGER,
          Role.ROLE_CLIENT_SENIOR_MANAGER
        ]
      },
      {
        active: false,
        title: 'menu.documents.fillRules',
        routerLink: '/documents/fill-rules',
        icon: 'fa-file-pdf-o',
        class: 'hidden-sm-down',
        roles: [
          Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
          Role.ROLE_CLIENT_ENGINEER
        ]
      }
    ]
  },
  {
    title: 'menu.orders.title',
    routerLink: '/orders',
    icon: 'fa-file-o',
    selected: false,
    active: false,
    expanded: false,
    order: 1,
    roles: [
      Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
      Role.ROLE_CLIENT_SENIOR_MANAGER
    ],
    modules: [Module.ORDERS],
    class: 'hidden-sm-down',
    subMenu: [
      {
        active: false,
        title: 'menu.orders.orders',
        routerLink: '/orders/orders',
        icon: 'fa-file-text-o'
      },
      {
        active: false,
        title: 'menu.orders.serial-numbers',
        routerLink: '/orders/serial-numbers',
        icon: 'fa-barcode'
      },
      {
        active: false,
        title: 'menu.orders.invoices',
        routerLink: '/orders/invoices',
        icon: 'ico-receipt',
        roles: [Role.ROLE_CLIENT_EMPLOYEE_ADMIN]
      },
      {
        active: false,
        title: 'menu.orders.expenses',
        routerLink: '/orders/expenses',
        icon: 'fa-money',
        roles: [Role.ROLE_CLIENT_EMPLOYEE_ADMIN]
      },
      {
        active: false,
        title: 'menu.orders.point',
        routerLink: '/orders/points',
        icon: 'ico-lane-departure',
        roles: [Role.ROLE_CLIENT_EMPLOYEE_ADMIN]
      }
    ]
  },
  {
    title: 'planning.title',
    routerLink: '/planning',
    icon: 'fa-tasks',
    selected: false,
    active: false,
    expanded: false,
    order: 1,
    roles: [
      Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
      Role.ROLE_CLIENT_SENIOR_MANAGER
    ],
    modules: [Module.PLANNING],
    class: 'hidden-sm-down',
    onlyCertainAccounts: true,
    subMenu: [
      {
        active: false,
        title: 'planning.panner',
        routerLink: '/planning',
        icon: 'fa-television'
      },
      {
        active: false,
        title: 'planning.regions.regions',
        routerLink: '/planning/regions',
        icon: 'fa-list-ol'
      },
    ]
  },
  {
    title: 'menu.calendar.title',
    routerLink: '/calendar',
    icon: 'fa-calendar',
    selected: false,
    active: false,
    expanded: false,
    order: 1,
    roles: [
      Role.ROLE_CLIENT_EMPLOYEE_ADMIN,
      Role.ROLE_CLIENT_EMPLOYEE_MANAGER,
      Role.ROLE_CLIENT_ENGINEER,
      Role.ROLE_CLIENT_SENIOR_MANAGER
    ],
    modules: [Module.CALENDAR],
    class: 'hidden-sm-down'
  }
];
