import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TachoTableEntry} from '../model/tacho/table/tacho-table.entry';
import {TachoLatestInfoChart} from '../model/tacho/tacho-latest-info-chart';
import {HttpClient} from '@angular/common/http';
import {VehiclePathResponse} from '../../pages/client/monitor/tacho/model/vehicle-path-response';
import {UntypedFormGroup} from "@angular/forms";

@Injectable()
export class GlobalTachoService {

  constructor(private http: HttpClient) {
  }

  public getLatestInfoCharts(
    driverCard: string,
    fromTill: UntypedFormGroup,
    vehicle: number): Observable<TachoLatestInfoChart> {

    let from = fromTill.get('from')?.value;
    let till = fromTill.get('till')?.value;
    return this.http.get<TachoLatestInfoChart>(`/api/tachograph/info-chart/${vehicle}/${driverCard}/${from}/${till}`);
  }

  public getTable(driverCards: string[]): Observable<TachoTableEntry> {
    return this.http.get<TachoTableEntry>(`/api/tachograph/table?driverCards=${driverCards.join(',')}`);
  }

  public getPosition(driverCard: string,
                     startTime: number,
                     endTime: number,
                     vehicleId: number): Observable<VehiclePathResponse> {

    return this.http.get<VehiclePathResponse>(
      `/api/tachograph/vehicle-path/${driverCard}/${startTime}/${endTime}?vehicleId=${vehicleId}`);
  }
}
