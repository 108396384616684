import Feature from 'ol/Feature';
import {v4 as UUID} from 'uuid';
import Point from 'ol/geom/Point';
import {Fill, Icon, Stroke, Style, Text} from 'ol/style';
import {Map} from 'ol/Map';
import {fromLonLat, transform} from 'ol/proj';
import {LatestVehicleEntry} from '../../../../core/component/latest-vehicles-info/model/latest-vehicle-entry';
import Polyline from 'ol/format/Polyline';
import {MapUtils} from '../../../../core/commons/map.utils';
import LineString from 'ol/geom/LineString';
import {MathUtils} from '../../../../core/commons/math-utils';
import {QueueTailEntry, QueueTailPoint} from '../../../../core/component/intersections/queueTailEntry';
import {Alphabet} from '../../../../core/model/alphabet';
import {ConnectedTrailerEntry} from '../../../../core/component/latest-vehicles-info/model/connected-trailer.entry';
import {
  PartnerPositionVehicleCacheEntry
} from '../../partners/positions/partner-position-track/model/partner-position-vehicle-cache.entry';
import {MapSettings} from '../../../../core/model/map.constants';
import {ProTasksQueueEntry} from '../../tasks/pro-tasks-manager/queue/model/pro-tasks-queue.entry';
import {ProTasksPreviewEntry} from '../../tasks/pro-tasks-manager/queue/model/pro-tasks-preview.entry';
import {LastDoneProTaskEntry} from '../../tasks/pro-tasks-manager/queue/model/last-done-pro-task.entry';
import {MapPosition} from '../../tasks/route/metamodel';
import {MapPOIEntry} from '../../tasks/tasks-manager/tasks-poi/model/map-poi.entry';
import {POIFuelingPriceGroup} from '../../poi/poi/model/poi-fueling-price-group';
import {MapFeatureTypes} from './map-feature.types';
import {AuthStorage} from '../../../../core/auth/auth-storage';
import {PoiUnveroEntry} from '../../tasks/pro-tasks-manager/form/model/unvero/poi-unvero.entry';
import {VehiclePositionEntry} from '../tacho/model/vehicle-position.entry';
import {VehiclePathInfo} from '../tacho/model/vehicle-path-info';

export class MapFeatureUtils {


  public static newOLMarker(coords, text, type?, denomination?, key?, uuid?) {
    let iconFeature = new Feature({
      name: uuid ? uuid : UUID(),
      geometry: new Point(fromLonLat(coords)),
      type: type ? type : 'map-marker',
      text: text,
      key: key
    });

    let iconStyle = new Style({
      image: this.getMarkerIcon('assets/img/marker/addr_search.svg'),
    });

    let denomStyle;
    if (denomination) {
      denomStyle = new Style({
        text: new Text({
          font: 'bold 13px sans-serif',
          text: denomination,
          padding: [1, 2, 1, 2],
          textAlign: 'center',
          textPlacement: 'line',
          fill: new Fill({
            color: '#ffffff'
          }),
          backgroundFill: new Fill({
            color: '#0099cc'
          }),
          offsetX: 0.5,
          offsetY: -23
        })
      })
    }
    if (denomStyle) {
      iconFeature.setStyle([iconStyle, denomStyle]);
    } else {
      iconFeature.setStyle(iconStyle);
    }
    return iconFeature;
  }

  public static newPathMarker(infos: VehiclePathInfo[], distance: number): Feature {
    let endPoint = infos[0].endPoint;
    let iconFeature = new Feature({
      name: UUID(),
      geometry: new Point(fromLonLat([endPoint.longitude, endPoint.latitude])),
      type: MapFeatureTypes.DRIVER_PATH_MARKER,
      distance: distance,
      infos: infos
    });
    iconFeature.setStyle(new Style({
      image: this.getMarkerIcon('assets/img/marker/driver_path.svg'),
    }));
    return iconFeature;
  }

  public static getTrailerSwapIcon(): any {
    return new Style({
      image: new Icon(({
        src: 'assets/img/proTasks/markers/TYPE/TRAILER_CHANGE.svg',
        color: '#33d0d0',
        scale: 0.5,
        anchor: [0.5, 0.97],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction'
      })),
      text: new Text({
        font: 'bold 11px sans-serif',
        text: '?',
        padding: [1, 2, 1, 2],
        textAlign: 'center',
        textPlacement: 'line',
        fill: new Fill({
          color: '#242D3A'
        }),
        offsetY: -13
      })
    });
  }

  public static getMarkerIcon(src: string): any {
    return new Icon(({
      anchor: [0.5, 1],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction',
      src: src,
      scale: 1,
    }));
  }

  public static getPlateNumber(text: string, serviceInRed?: boolean): Text {
    return new Text({
      font: '11px sans-serif',
      text: text,
      padding: [4, 2, 2, 3],
      textAlign: 'center',
      textPlacement: 'line',
      fill: new Fill({
        color: '#333',
      }),
      backgroundFill: new Fill({
        color: serviceInRed !== null
          ? serviceInRed ? 'rgb(255,198,195)' : 'rgb(255,234,173)'
          : '#ffffff',
      }),
      backgroundStroke: new Stroke({
        lineCap: 'round',
        color: '#242D3A',
        lineJoin: 'round',
        width: 1,
        miterLimit: 50
      }),
      offsetY: 20
    });
  }

  public static getText(text: string, type?: string) {
    return new Text({
      font: '11px sans-serif',
      text: text,
      padding: [4, 2, 2, 3],
      textAlign: 'center',
      textPlacement: 'line',
      backgroundFill: new Fill({
        color: '#fff'
      }),
      backgroundStroke: new Stroke({
        lineCap: 'round',
        color: '#242D3A',
        lineJoin: 'round',
        width: 1,
        miterLimit: 50
      }),
      offsetY: type === 'map-marker' ? 12 : 20
    });
  }


  public static newOLTruckMarker(vehicle: LatestVehicleEntry, shouldntShowText?: boolean) {
    let iconFeature = new Feature({
      geometry: new Point(fromLonLat([vehicle.position.longitude, vehicle.position.latitude])),
      name: vehicle,
      type: 'truck'
    });

    let iconStyle = new Style({
      image: new Icon(({
        anchor: [14, 14],
        anchorXUnits: 'pixels',
        anchorYUnits: 'pixels',
        src: vehicle.getIcon(),
        rotation: MathUtils.radians(vehicle.direction)
      })),
      text: shouldntShowText ? null : this.getOLTruckText(vehicle, this.isVehicleWarningRed(vehicle))
    });

    iconFeature.setStyle(iconStyle);
    return iconFeature;
  }

  private static getOLTruckText(vehicle: LatestVehicleEntry, vehicleIsRed: boolean): Text {
    let plateNumberText = `${vehicle.plateNumber}`;
    if (vehicle.connectedTrailer != null) {
      plateNumberText += ` | ${vehicle.connectedTrailer.plateNumber}`
    }
    let mapConfigs = AuthStorage.getMapConfigs();
    if (mapConfigs != null && mapConfigs.showDriverInMarker && vehicle.driver != null) {
      plateNumberText += ` | ${vehicle.driver.value}`
    }
    return MapFeatureUtils.getPlateNumber(plateNumberText, vehicleIsRed);
  }

  private static isVehicleWarningRed(vehicle: LatestVehicleEntry): boolean {
    if (vehicle.serviceInRed === null && this.hasNoLateOrSoonNotes(vehicle)) {
      return null;
    }
    return vehicle.serviceInRed || this.hasLateNotices(vehicle);
  }

  private static hasNoLateOrSoonNotes(vehicle: LatestVehicleEntry) {
    return vehicle.noteStatuses != null && vehicle.noteStatuses.length > 0
      ? vehicle.noteStatuses.filter(it => it.noteStatus === 'LATE' || it.noteStatus === 'SOON').length === 0
      : true
  }

  private static hasLateNotices(vehicle: LatestVehicleEntry) {
    return vehicle.noteStatuses != null ? vehicle.noteStatuses.map(it => it.noteStatus).includes('LATE') : false;
  }

  public static newOLTrailerMarker(trailer: ConnectedTrailerEntry) {
    let iconFeature = new Feature({
      geometry: new Point(fromLonLat([trailer.position.longitude, trailer.position.latitude])),
      name: trailer,
      type: 'truck'
    });

    let iconStyle = new Style({
      image: new Icon(({
        anchor: [14, 14],
        anchorXUnits: 'pixels',
        anchorYUnits: 'pixels',
        src: trailer.getIcon(),
        rotation: MathUtils.radians(trailer.direction)
      })),
      text: MapFeatureUtils.getPlateNumber(trailer.plateNumber, trailer.serviceInRed)
    });

    iconFeature.setStyle(iconStyle);
    return iconFeature;
  }

  public static getOLPolyline(
    polyline: any,
    type: string,
    fromCoordinates?: boolean,
    key?: number,
    index?: number,
    uuid?: string,
    description?: string) {

    let route = null;
    if (fromCoordinates) {
      route = new LineString(polyline).transform('EPSG:4326', 'EPSG:3857');
    } else {
      route = new Polyline({
        factor: 1e5
      }).readGeometry(polyline, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      });
    }
    let feature = new Feature({
      type: type,
      geometry: route,
      name: uuid ? uuid : UUID(),
      key: key ? key : null,
      idx: index ? index : null,
      description: description ? description : null
    });
    return feature;
  }

  public static getQueueTailPolyline(queueTail: QueueTailEntry, color: string = '#ff0000') {
    let route = new Polyline({
      factor: 1e5
    }).readGeometry(queueTail.polyline, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857'
    });
    let feature = new Feature({
      geometry: route,
      name: UUID(),
      idx: queueTail.vehicleId
    });
    feature.setStyle(new Style({
      stroke: new Stroke({
        width: 4,
        color: color
      })
    }));
    return feature;
  }

  public static getOLPointFeature(lonlat: any, type: string, entry?: any, key?: any, uuid?: string) {
    return new Feature({
      geometry: new Point(fromLonLat(lonlat)),
      name: uuid ? uuid : UUID(),
      type: type,
      entry: entry ? entry : null,
      key: key ? key : null
    });
  }

  public static getPointFeature(evt, type) {
    return new Feature({
      geometry: new Point(evt.coordinate),
      type: type
    });
  }

  public static getPoiMarker(
    poi: MapPOIEntry,
    index: number,
    uuid: string,
    lonlat,
    color: string,
    important?: boolean,
    applyStyle?: boolean) {

    let marker = new Feature({
      geometry: new Point(fromLonLat(lonlat)),
      name: uuid,
      entry: poi,
      index: index,
      text: poi.name,
      type: MapFeatureTypes.POI,
      color: color,
      important: important
    });
    if (applyStyle) {
      marker.setStyle(this.getFormStyles(poi, color, important));
    }
    return marker;
  }

  public static getPOIMainStyle(color: string, important: boolean) {
    return new Style({
      image: new Icon({
        color: color,
        crossOrigin: 'anonymous',
        src: 'assets/img/poi/white_marker.svg',
        scale: important ? 0.45 : 0.3
      })
    });
  }

  public static getPOISupplementStyle(poi: MapPOIEntry, important: boolean) {
    return new Style({
      image: new Icon({
        color: this.determinePoiSupplementColor(poi.priceGroup),
        crossOrigin: 'anonymous',
        anchor: important ? [0.5, 0.65] : [0.5, 0.9],
        src: important ? 'assets/img/poi/white_exclamation.svg' : 'assets/img/poi/white_dot.svg',
        scale: important ? 0.45 : 0.2
      })
    });
  }

  private static getFormStyles(poi: MapPOIEntry, color: string, important: boolean): Style[] {
    let mainStyle = new Style({
      image: new Icon({
        color: color,
        crossOrigin: 'anonymous',
        src: 'assets/img/poi/white_marker.svg'
      })
    });
    let supplementStyle: Style;
    if (important) {
      mainStyle.getImage().setScale(0.45);
      supplementStyle = new Style({
        image: new Icon({
          color: this.determinePoiSupplementColor(poi.priceGroup),
          anchor: [0.5, 0.65],
          crossOrigin: 'anonymous',
          src: 'assets/img/poi/white_exclamation.svg',
          scale: 0.45
        })
      });
    } else {
      mainStyle.getImage().setScale(0.3);
      supplementStyle = new Style({
        image: new Icon({
          color: this.determinePoiSupplementColor(poi.priceGroup),
          anchor: [0.5, 0.9],
          crossOrigin: 'anonymous',
          src: 'assets/img/poi/white_dot.svg',
          scale: 0.2
        })
      });
    }

    return [mainStyle, supplementStyle];
  }

  private static determinePoiSupplementColor(priceGroup: POIFuelingPriceGroup): string {
    switch (priceGroup) {
      case POIFuelingPriceGroup.LOW:
        return '#00e100';
      case POIFuelingPriceGroup.MEDIUM:
        return '#f5e100';
      case POIFuelingPriceGroup.HIGH:
        return '#e10000';
      default:
        return '#ffffff'
    }
  }

  public static queueTailPointInfo(content: QueueTailPoint, vehicleId: number): Feature {
    let marker = new Feature({
      name: UUID(),
      geometry: new Point(fromLonLat([content.longitude, content.latitude])),
      index: content.idx,
      type: 'alphabet',
      vehicleId: vehicleId
    });
    marker.setStyle(new Style({
      image: new Icon(({
        src: `assets/img/proTasks/markers/${content.type}` +
          `/${content.executing ? 'EXECUTING' : content.status}` +
          `/${Alphabet.ARRAY[content.idx]}.png`,
        anchor: [0.55, 0.97],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction'
      }))
    }));

    return marker;
  }

  public static directionsToPoint(coordinates: any[]) {
    let lngLat = transform(coordinates, 'EPSG:3857', 'EPSG:4326');
    return {
      address: MapUtils.parseOLCoordinates(coordinates),
      lat: +lngLat[1].toFixed(6),
      lng: +lngLat[0].toFixed(6)
    }
  }

  public static featureToPoints(feature: Feature) {
    let lngLat = transform(feature.getGeometry().getCoordinates(), 'EPSG:3857', 'EPSG:4326');
    return {
      name: feature.values_.text,
      lat: +lngLat[1].toFixed(6),
      lng: +lngLat[0].toFixed(6)
    }
  }

  public static clusterPointStyle(size: any) {
    return new Style({
      image: new Icon({
        src: `assets/img/marker/m1.png`,
      }),
      text: new Text({
        text: size.toString(),
        font: 'bold 11px sans-serif',
        anchorXUnits: 'pixels',
        anchorYUnits: 'pixels',
        padding: [0, 1, 0, 1],
        fill: new Fill({
          color: '#fff'
        }),
      }),
    });
  }

  public static singleClusterPointStyle(vehicle: LatestVehicleEntry) {
    return new Style({
      image: new Icon(({
        anchor: [14, 14],
        anchorXUnits: 'pixels',
        anchorYUnits: 'pixels',
        src: vehicle.getIcon(),
        rotation: MathUtils.radians(vehicle.direction)
      })),
      text: MapFeatureUtils.getPlateNumber(vehicle.plateNumber, this.isVehicleWarningRed(vehicle))
    });
  }

  public static sharedVehicleMarker(vehicle: PartnerPositionVehicleCacheEntry) {
    let iconFeature = new Feature({
      geometry: new Point(fromLonLat([vehicle.position.longitude, vehicle.position.latitude])),
      name: vehicle,
      type: 'truck'
    });

    let iconStyle = new Style({
      image: new Icon(({
        anchor: [14, 14],
        anchorXUnits: 'pixels',
        anchorYUnits: 'pixels',
        src:  MapSettings.ICONS['truck'][vehicle.status] + '#rotate_' + vehicle.direction,
        rotation: MathUtils.radians(vehicle.direction)
      })),
      text: new Text({
        font: 'bold 11px sans-serif',
        text: vehicle.plateNumber,
        padding: [4, 2, 2, 3],
        textAlign: 'center',
        textPlacement: 'line',
        backgroundFill: new Fill({
          color: '#ffffff',
        }),
        backgroundStroke: new Stroke({
          lineCap: 'round',
          color: '#242D3A',
          lineJoin: 'round',
          width: 1,
          miterLimit: 50
        }),
        offsetY: 20
      })
    });

    iconFeature.setStyle(iconStyle);
    return iconFeature;
  }

  public static trackTagStyle(first: boolean, alt: boolean) {
    let text = first ? 'A' : 'B';
    text += alt ? 1 : '';
    return new Style({
      image: new Icon({
        src: 'assets/img/marker/marker-without-dot.svg',
        anchor: [0.5, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        crossOrigin: 'anonymous',
        scale: alt ? 1.2 : 1
      }),
      text: new Text({
        text: text,
        font: 'bold 12px sans-serif',
        textAlign: 'center',
        textPlacement: 'line',
        fill: new Fill({
          color: '#ffffff'
        }),
        anchorXUnits: 'pixels',
        anchorYUnits: 'pixels',
        offsetY: alt ? -28 : -23,
        offsetX: 0.5
      })
    });
  }

  /******************************************************* TASKS FEATURES ********************************************/

  public static getTaskOLPolyline(polyline: any,
                                  type: string,
                                  uuid?: string,
                                  key?: number,
                                  index?: number) {

    let route = new Polyline({ factor: 1e5 }).readGeometry(polyline, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      });
    return new Feature({
      type: type,
      geometry: route,
      name: uuid ? uuid : UUID(),
      key: key ? key : null,
      idx: index ? index : null
    });
  }

  public static olQueueTaskMarker(content: ProTasksQueueEntry, type: string, executing: boolean, idx?: number) {
    let iconFeature = new Feature({
      name: UUID(),
      geometry: new Point([content.destination.lng, content.destination.lat]).transform('EPSG:4326', 'EPSG:3857'),
      type: type,
      key: content
    });
    let iconStyle = this.getTaskMarkerStyle(content.type, content.status, executing, idx, false);
    iconFeature.setStyle(iconStyle);
    return iconFeature;
  }

  public static olFormTaskMarker(position: any,
                                 uuid: string,
                                 type: string,
                                 status: string,
                                 taskType: string,
                                 executing: boolean,
                                 idx: number,
                                 numbers?: boolean) {

    let iconFeature = new Feature({
      name: uuid,
      geometry: new Point([position.lng, position.lat]).transform('EPSG:4326', 'EPSG:3857'),
      type: type,
      key: null
    });
    let iconStyle = this.getTaskMarkerStyle(taskType, status, executing, idx, status === 'ORIGIN', numbers);
    iconFeature.setStyle(iconStyle);
    return iconFeature;
  }

  private static getTaskMarkerStyle(type: string,
                                    status: string,
                                    executing: boolean,
                                    idx: number,
                                    origin: boolean,
                                    numbers?: boolean) {

    let text = null;
    if (idx != null) {
      text = numbers ? '' + (idx + 1) : Alphabet.ARRAY[idx];
    }
    return new Style({
      image: new Icon(({
        src: origin ? `assets/img/proTasks/markers/waypoints/ORIGIN.svg` : `assets/img/proTasks/markers/TYPE/${type}.svg`,
        color: executing ? '#13C674' : MapFeatureUtils.resolveTaskColor(status),
        scale: 0.5,
        anchor: [0.5, 0.97],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction'
      })),
      text: new Text({
        font: 'bold 11px sans-serif',
        text: text,
        padding: [1, 2, 1, 2],
        textAlign: 'center',
        textPlacement: 'line',
        fill: new Fill({
          color: '#242D3A'
        }),
        offsetY: -13
      })
    });
  }

  private static resolveTaskColor(taskStatus: string): string {
    switch (taskStatus) {
      case 'UNCONFIRMED':
        return '#E1472C'
      case 'TODO':
        return '#FDC500'
      default:
        return '#1D71BF';
    }
  }

  public static olPreviewedTaskMarker(content: ProTasksPreviewEntry, type: string) {
    let iconFeature = new Feature({
      name: content.uuid,
      geometry: new Point([content.destination.lng, content.destination.lat]).transform('EPSG:4326', 'EPSG:3857'),
      type: type,
      key: content
    });
    let iconStyle = new Style({
      image: new Icon(({
        src: `assets/img/proTasks/markers/TYPE/${content.type}/${content.status}/${Alphabet.ARRAY[content.idx]}.png`,
        anchor: [0.55, 0.97],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction'
      }))
    });
    iconFeature.setStyle(iconStyle);
    return iconFeature;
  }

  public static olLastTaskMarker(data: LastDoneProTaskEntry, type: string) {
    let iconFeature = new Feature({
      name: UUID(),
      geometry: new Point([data.destination.lng, data.destination.lat]).transform('EPSG:4326', 'EPSG:3857'),
      type: type,
      key: data
    });
    let iconStyle = new Style({
      image: new Icon(({
        src: `assets/img/proTasks/markers/DONE/${data.type}.svg`,
      }))
    });
    iconFeature.setStyle(iconStyle);
    return iconFeature;
  }

  public static getDefaultTaskMarker(position: any, uuid: string, src: string, type: string, content?: any) {
    let iconFeature = new Feature({
      name: uuid,
      geometry: new Point([position.lng, position.lat]).transform('EPSG:4326', 'EPSG:3857'),
      type: type,
      key: content != null ? content : null
    });
    let iconStyle = new Style({
      image: new Icon(({
        src: src,
        anchor: [0.5, 0.95],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction'
      }))
    });
    iconFeature.setStyle(iconStyle);
    return iconFeature;
  }

  public static getTaskGeometry(polyline: any) {
    return new Polyline({ factor: 1e5 }).readGeometry(polyline, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857'
    });
  }

  public static olWaypointMarker(position: any, uuid: string, idx: number, activeForm: boolean, type: string) {
    let marker = new Feature({
      name: uuid,
      geometry: new Point([position.lng, position.lat]).transform('EPSG:4326', 'EPSG:3857'),
      type: type,
      idx: idx
    });
    marker.setStyle(this.olWaypointMarkerStyle(activeForm));
    return marker;
  }

  public static olWaypointMarkerStyle(activeForm: boolean) {
    return new Style({
      image: new Icon(({
        src: activeForm
          ? `assets/img/proTasks/markers/waypoints/WAYPOINT_ACTIVE.svg`
          : `assets/img/proTasks/markers/waypoints/WAYPOINT_INACTIVE.svg`,
        scale: 1,
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction'
      }))
    });
  }

  public static olRestMarker(lat: number, lng: number, type: string, iconPath: string) {
    let marker = new Feature({
      name: UUID(),
      geometry: new Point([lng, lat]).transform('EPSG:4326', 'EPSG:3857'),
      type: type
    });
    marker.setStyle(new Style({
      image: new Icon(({
        src: iconPath,
        anchor: [0.5, 0.85],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction'
      }))
    }));
    return marker;
  }

  public static olPOIFavoriteMarker(lat: number, lng: number, type: string, iconPath: string, station: any) {
    let marker = this.toPOIFeature(lng, lat, type, station);
    marker.setStyle(new Style({
        image: new Icon(({
          src: iconPath,
          anchor: [0.5, 0.7],
          scale: 0.8,
        }))
      })
    )
    return marker;
  }

  public static olPOIStationMarker(lat: number, lng: number, type: string, iconPath: string, station: any) {
    let marker = this.toPOIFeature(lng, lat, type, station);

    if (station.fuelPrice != null) {
      marker.setStyle(this.getOlFuelingStyle(iconPath, station.fuelPrice));
    } else if (station.globalTankPOIPrices != null) {
      marker.setStyle(this.getOlFuelingStyle(iconPath, station.globalTankPOIPrices.dieselPrice));
    } else {
      marker.setStyle(this.getOlParkingStyle(iconPath));
    }
    return marker;
  }

  public static olUnveroStationMarker(type: string, iconPath: string, priceToShow: number, station: PoiUnveroEntry) {
    let marker = this.toPOIFeature(station.lng, station.lat, type, station);
    let style = this.getOlFuelingStyle(iconPath, priceToShow);
    if (station.cheapestInCountry) {
      style.setZIndex(5)
    }
    marker.setStyle(style);
    return marker;
  }

  private static toPOIFeature(lng: number, lat: number, type: string, station: any) {
    return new Feature({
      name: UUID(),
      geometry: new Point([lng, lat]).transform('EPSG:4326', 'EPSG:3857'),
      type: type,
      key: station
    });
  }

  private static getOlParkingStyle(iconPath: string) {
    return new Style({
      image: new Icon(({
        src: iconPath,
        anchor: [0.5, 25],
        anchorYUnits: 'pixels'
      }))
    });
  }

  private static getOlFuelingStyle(iconPath: string, fuelPrice: number) {
    return new Style({
      image: new Icon(({
        src: iconPath,
        anchor: [0.5, 25],
        anchorYUnits: 'pixels'
      })),
      text: new Text({
        font: '11px sans-serif',
        text: fuelPrice ? fuelPrice.toString() : '',
        padding: [4, 2, 2, 3],
        textAlign: 'center',
        textPlacement: 'line',
        backgroundFill: new Fill({
          color: '#ffffff'
        }),
        backgroundStroke: new Stroke({
          lineCap: 'square',
          color: '#242D3A',
          lineJoin: 'miter',
          width: 1,
          miterLimit: 50
        }),
        offsetY: -33
      })
    });
  }

  public static getTrainOrFerryLine(
    originLatLng: MapPosition,
    destinationLatLng: MapPosition,
    uuid: string,
    color: number[]) {

    let origin = fromLonLat([originLatLng.lng, originLatLng.lat]);
    let destination = fromLonLat([destinationLatLng.lng, destinationLatLng.lat]);
    let feature = new Feature({
      name: uuid,
      geometry: new LineString([origin, destination])
    });
    let lineStyle = new Style({
      stroke: new Stroke({
        color: color,
        width: 3,
        lineDash: [4, 8]
      })
    });
    feature.setStyle(lineStyle);
    return feature;
  }

  public static pulseFeatureText(feature: Feature, map: Map, keys: any[]) {
    // green <-> white
    const colorR = {min: 3, max: 252};   // 249 diff ~x3 multiplier
    const colorG = {min: 167, max: 254}; //  87 diff ~x1 multiplier
    const colorB = {min: 92, max: 253};  // 161 diff ~x2 multiplier

    let toGreen = true;
    let red = 255;
    let green = 255;
    let blue = 255;
    let opacity = 1;

    const pulse = () => {
      if (toGreen && (red > colorR.min && green > colorG.min && blue > colorB.min)) {
        red = red - 3;
        green = green - 1;
        blue = blue - 2;
      } else if (!toGreen && (red < colorR.max && green < colorG.max && blue < colorB.max)) {
        red = red + 3;
        green = green + 1;
        blue = blue + 2;
      } else {
        toGreen = !toGreen;
      }

      feature.getStyle().getText().setBackgroundFill(
        new Fill({
          color: [red, green, blue, opacity]
        })
      );
      feature.changed();
    }

    let listenerKey = map.on('postcompose', pulse);
    keys.push(listenerKey);
  }

  public static getRoutePath(positions: VehiclePositionEntry[]) {
    let path = [];
    positions.forEach(position => {
      path.push([position.longitude, position.latitude]);
    });
    return path;
  }
}
