import {Injectable, OnDestroy} from '@angular/core';
import {Client as StompClient, over as overStomp} from 'stompjs';
import * as SockJS from 'sockjs-client';
import {GlobalService} from '../global/global.service';
import {Subject, Subscription, timer} from 'rxjs';
import {UserWsInfo} from './model/user-ws-info';
import {AuthStorage} from '../auth/auth-storage';
import {debounceTime, delay, retryWhen, switchMap} from 'rxjs/operators';

const ALARM_COUNT_TOPIC = `/users/alarm/alarm-count`;
const NOTICE_COUNT_TOPIC = `/users/notice/notice-count`;
const PERSONAL_NOTIFICATION_COUNT_TOPIC = `/users/personal/notification-count`;
const ALARM_WS_ENDPOINT = `/app/alarms-ws`;

const CONNECTION_TIMEOUT = 60000;
const RECONNECT_TIMEOUT = 60000;
const THROTTLE_TIME = 1000;
const HEARTBEAT_EVERY_MINUTE = 1000 * 60;

enum AlarmWsConnectionType {
  ALARM = 'ALARM',
  NOTICE = 'NOTICE',
  PERSONAL = 'PERSONAL'
}

@Injectable({
  providedIn: 'root'
})
export class AlarmsWebSocketService implements OnDestroy {

  private stompClient: StompClient;

  private debouncedUpdateAlarm = new Subject<void>();
  private debouncedUpdateNotice = new Subject<void>();
  private debouncedUpdatePersonalNotification = new Subject<void>();

  private debouncedUpdateAlarmSubs: Subscription;
  private debouncedUpdateNoticeSubs: Subscription;
  private debouncedUpdatePersonalNotificationSubs: Subscription;

  private alarmCountSource = new Subject<number>();
  public alarmCount$ = this.alarmCountSource.asObservable();

  private noticeCountSource = new Subject<number>();
  public noticeCount$ = this.noticeCountSource.asObservable();

  private personalNotificationCountSource = new Subject<number>();
  public personalNotificationCount$ = this.personalNotificationCountSource.asObservable();

  constructor(private globalService: GlobalService) {
    this.debouncedUpdateAlarmSubs = this.debouncedUpdateAlarm
      .pipe(debounceTime(THROTTLE_TIME))
      .subscribe(() => this.sendUpdateAlarmSubs());

    this.debouncedUpdateNoticeSubs = this.debouncedUpdateNotice
      .pipe(debounceTime(THROTTLE_TIME))
      .subscribe(() => this.sendUpdateNoticeSubs());

    this.debouncedUpdatePersonalNotificationSubs = this.debouncedUpdatePersonalNotification
      .pipe(debounceTime(THROTTLE_TIME))
      .subscribe(() => this.sendUpdatePersonalNotificationSubs());
  }

  public getUserAndConnect() {
    if (AuthStorage.isLoggedClientEmployee()) {
      this.globalService.userAlarmWsInfo().pipe(
        retryWhen(errors =>
          errors.pipe(
            switchMap(() => timer(60000))
          )
        )
      ).subscribe(
        userInfo => this.connect(userInfo)
      );
    }
  }

  private connect(userInfo: UserWsInfo): void {
    const ws = new SockJS(userInfo.url, null, {timeout: CONNECTION_TIMEOUT});
    this.stompClient = overStomp(ws);
    this.stompClient.heartbeat.outgoing = HEARTBEAT_EVERY_MINUTE;
    this.stompClient.heartbeat.incoming = HEARTBEAT_EVERY_MINUTE;
    this.disableLogs();

    const headers = {
      employeeId: userInfo.employeeId,
      clientId: userInfo.clientId,
      token: userInfo.token,
      accountId: userInfo.accountId,
    };

    const self = this;
    this.stompClient.connect(headers, function() {
      self.stompClient.subscribe(ALARM_COUNT_TOPIC, (event) => {
        self.handleAlarmCount(JSON.parse(event.body));
      });
      self.stompClient.subscribe(NOTICE_COUNT_TOPIC, (event) => {
        self.handleNoticeCount(JSON.parse(event.body));
      });
      self.stompClient.subscribe(PERSONAL_NOTIFICATION_COUNT_TOPIC, (event) => {
        self.handlePersonalNotificationCount(JSON.parse(event.body));
      });
    }, this.errorCallBack);
  }

  private handleAlarmCount = (alarmCount: number) => {
    this.alarmCountSource.next(
      alarmCount
    );
  }

  private handleNoticeCount = (noticeCount: number) => {
    this.noticeCountSource.next(
      noticeCount
    );
  }

  private handlePersonalNotificationCount = (notificationCount: number) => {
    this.personalNotificationCountSource.next(
      notificationCount
    );
  }

  public errorCallBack = () => {
    this.reconnect();
  }

  private reconnect() {
    setTimeout(() => this.getUserAndConnect(), RECONNECT_TIMEOUT);
  }

  public updateAlarmSubs() {
    this.debouncedUpdateAlarm.next();
  }

  public sendUpdateAlarmSubs() {
    if (this.stompClient) {
      this.stompClient.send(ALARM_WS_ENDPOINT, {}, AlarmWsConnectionType.ALARM);
    }
  }

  public updateNoticeSubs() {
    this.debouncedUpdateNotice.next();
  }

  public sendUpdateNoticeSubs() {
    if (this.stompClient) {
      this.stompClient.send(ALARM_WS_ENDPOINT, {}, AlarmWsConnectionType.NOTICE);
    }
  }

  public updatePersonalNotificationSubs() {
    this.debouncedUpdatePersonalNotification.next();
  }

  public sendUpdatePersonalNotificationSubs() {
    if (this.stompClient) {
      this.stompClient.send(ALARM_WS_ENDPOINT, {}, AlarmWsConnectionType.PERSONAL);
    }
  }

  private disableLogs() {
    this.stompClient.debug = () => {};
  }

  public disconnect(): void {
    if (this.stompClient) {
      this.stompClient.disconnect();
      this.stompClient = null;
    }
  }

  public ngOnDestroy() {
    this.debouncedUpdateAlarmSubs.unsubscribe();
    this.debouncedUpdateNoticeSubs.unsubscribe();
    this.debouncedUpdatePersonalNotificationSubs.unsubscribe();
    this.disconnect();
  }
}
