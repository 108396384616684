import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {TachoState} from './tacho/tacho-state';
import {VehiclePathResponse} from "./tacho/model/vehicle-path-response";

@Injectable()
export class MonitorService {

  private tachoStateSource = new Subject<TachoState>();
  public tachoState$ = this.tachoStateSource.asObservable();

  private tachoWindowStateSource = new Subject<boolean>();
  public tachoWindowState$ = this.tachoWindowStateSource.asObservable();

  private tachoTypeSource = new Subject<string>();
  public tachoTypeSource$ = this.tachoTypeSource.asObservable();

  private clusterClickVehicleSource = new Subject<number>();
  private clusterClickVehicle$ = this.clusterClickVehicleSource.asObservable();

  private showConnectedVehicleSource = new Subject<number>();
  private showConnectedVehicle$ = this.showConnectedVehicleSource.asObservable();

  private notifyRoutePlannerSource = new Subject<void>();
  public notifyRoutePlanner$: Observable<void> = this.notifyRoutePlannerSource.asObservable();

  private driverPathSource = new Subject<VehiclePathResponse>();
  public driverPathState$ = this.driverPathSource.asObservable();

  public clickedMonitorTacho(state: TachoState) {
    this.tachoStateSource.next(state);
  }

  public tachoWindowOpen(state: boolean) {
    this.tachoWindowStateSource.next(state)
  }

  public updateTachoType(type: string) {
    this.tachoTypeSource.next(type)
  }

  public tachoType(): Observable<string> {
    return this.tachoTypeSource$
  }

  public clusterClickVehicle(vehicleId: number) {
    this.clusterClickVehicleSource.next(vehicleId);
  }

  public clusterClickedVehicle(): Observable<number> {
    return this.clusterClickVehicle$
  }

  public notifyShowConnectedVehicle(connectedVehicle: number) {
    this.showConnectedVehicleSource.next(connectedVehicle);
  }

  public showConnectedTrailerObservable(): Observable<number> {
    return this.showConnectedVehicle$
  }

  public notifyRoutePlanner() {
    this.notifyRoutePlannerSource.next();
  }

  public showDriverPath(path: VehiclePathResponse) {
    this.driverPathSource.next(path);
  }

}
