import {Component, ViewEncapsulation} from '@angular/core';
import {MenuService} from './menu.service';
import {Permissions} from '../../../core/auth/permissions';
import {Role} from '../../../core/model/profile';
import {CookieService} from 'ngx-cookie';
import {AuthStorage} from '../../../core/auth/auth-storage';
import {GlobalConstants} from '../../../core/global/global.constants';
import {TranslateService} from '@ngx-translate/core';
import {LAST_SHOWN_TRACK} from '../../../core/global/cookies.constants';
import {DeviceService} from '../../../core/commons/device.service';
import {environment} from 'environments/environment';

@Component({
  selector: 'app-menu',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers: [MenuService]
})

export class MenuComponent {

  public menuItems: Array<any>;
  public showHoverElem: boolean;
  public hoverElemHeight: number;
  public hoverElemTop: number;
  public hoverSubMenu: number;
  public isClient: boolean;

  private environmentName: string = environment.name

  constructor(private menuService: MenuService,
              private cookieService: CookieService,
              private translate: TranslateService,
              private deviceService: DeviceService) {

    this.menuItems = menuService.getMenuItems();
    this.isClient = AuthStorage.isLoggedClientEmployee();
  }

  public hoverItem($event, item?: string): void {
    this.showHoverElem = true;
    this.hoverElemHeight = $event.currentTarget.clientHeight;
    this.hoverElemTop = $event.currentTarget.getBoundingClientRect().top - 60;
    if (item != null) {
      this.handleTooltip(item, $event)
    }
  }

  public canView(roles: Role[], modules: string[], partnerModules: string[], subcontractor?: boolean): boolean {
    return partnerModules != null
      ? this.validatePartner(roles, modules, partnerModules, subcontractor)
      : this.validate(roles, modules, subcontractor);
  }

  private validatePartner(roles: Role[],
                          modules: string[],
                          partnerModules: string[],
                          subcontractor?: boolean): boolean {

    return AuthStorage.isLoggedPartnerEmployee()
      ? Permissions.hasModules(partnerModules) && this.validate(roles, modules, subcontractor)
      : this.validate(roles, modules, subcontractor);
  }

  private validate(roles: Role[], modules: string[], subcontractor?: boolean): boolean {
    if (subcontractor != null) {
      return !Permissions.isSubcontractor() && Permissions.hasRoles(roles) && Permissions.hasModules(modules);
    }
    return Permissions.hasRoles(roles) && Permissions.hasModules(modules);
  }

  public getLastShownTrack(): number {
    return +this.cookieService.get(LAST_SHOWN_TRACK);
  }

  public openMenu($event, item?: any): boolean {
    if (this.isMobile()) {
      return false;
    }
    let link = jQuery($event.currentTarget);
    this.hoverSubMenu = $event.currentTarget.getBoundingClientRect().top - 61.5;
    link.next().removeClass('sidebar-sublist');
    if (item != null) {
      this.handleTooltip(item, $event)
    }
    return false;
  }

  public collapseMenu($event, item): boolean {
    let link = jQuery($event.currentTarget);
    if (link.next().hasClass('subitem-submenu-list')) {
      link.next().addClass('sidebar-sublist');
    } else {
      link.parent().addClass('sidebar-sublist');
    }
    return false;
  }

  public openMobileMenu($event): boolean {
    $event.stopPropagation();
    if (this.isMobile()) {
      let link = jQuery($event.currentTarget);
      if (link.next().hasClass('sidebar-sublist')) {
        link.next().removeClass('sidebar-sublist');
        return false;
      } else {
        link.next().addClass('sidebar-sublist');
        return false;
      }
    }
    return false;
  }

  public shouldMenuCollapse($event, item): boolean {
    let target = jQuery($event.relatedTarget);
    return target.hasClass('subsubitem') || target.parent().hasClass('subsubitem')
      ? false
      : this.collapseMenu($event, item);
  }

  public isMobile(): boolean {
    return window.innerWidth < GlobalConstants.MOBILE_WIDTH_BREAKPOINT;
  }

  public handleTooltip(item: any, event: any) {
    let menuText = <HTMLElement>event.target.querySelector('.menu-item-text');
    if (menuText.scrollWidth > menuText.clientWidth) {
      menuText.title = this.translate.instant(item.title)
    }
  }

  public showItem(hideInMobile: boolean): boolean {
    return !(this.deviceService.isMobile() && hideInMobile);
  }

  public onlyForClients(onlyForClients: any) {
    let clientId = AuthStorage.getClientId() != null ? AuthStorage.getClientId() : -1;
    switch (this.environmentName) {
      case 'DEV':
      case 'UAT':
      case 'LOCAL':
        return true;
      case 'PREPROD':
      case 'PROD':
        return onlyForClients == null || onlyForClients.includes(clientId)
      default:
        return onlyForClients == null;
    }
  }

  public onlyCertainAccounts(onlyCertainAccounts: boolean): boolean {
    if (onlyCertainAccounts) {
      let allowedAccounts = [21581];
      let loggedAccount = AuthStorage.getLoggedId() != null ? +AuthStorage.getLoggedId() : -1;
      switch (this.environmentName) {
        case 'DEV':
        case 'UAT':
        case 'LOCAL':
        case 'PREPROD':
          return true;
        case 'PROD':
          return allowedAccounts.includes(loggedAccount);
        default:
          return false;
      }
    }
    return true;
  }


  public setMenuActive(firstIndex: number, secondIndex?: number, thirdIndex?: number) {
    this.menuActiveReset();
    this.menuItems[firstIndex].active = true;
    if (secondIndex || secondIndex == 0) {
      this.menuItems[firstIndex].subMenu[secondIndex].active = true;
      if (thirdIndex || thirdIndex == 0) {
        this.menuItems[firstIndex].subMenu[secondIndex].subMenu[thirdIndex].active = true;
      }
    }
  }

  private menuActiveReset() {
    this.menuItems = this.menuItems.map((item) => {
      if (item.subMenu) {
        item.subMenu = item.subMenu.map((subItem) => {
          if (subItem.subMenu) {
            subItem.subMenu = subItem.subMenu.map((subSubItem) => {
              return {...subSubItem, active: false}
            })
          }
          return {...subItem, active: false}
        })
      }
      return {...item, active: false}
    })
  }
}
