<div class="chat-container">
  <div class="chat__content chat-list__container" id="feed-messages-container">
    <div *ngIf="vehicles == null || vehicles.length == 0" class="alert alert-info text-center" role="alert">
      {{'chat.list.no-subs-vehicles' | translate}}
    </div>
    <div class="chat-list__message" *ngFor="let vehicle of filteredVehicles">
      <div class="message__status">
        <span class="message__icon"
      (click)="openChat(vehicle)"
      [ngClass]="{'message--offline': displayInfo.get(vehicle.id) != null
                        && !displayInfo.get(vehicle.id).tabOnline,
                 'message--online': displayInfo.get(vehicle.id) != null
                        && displayInfo.get(vehicle.id).tabOnline,
                 'message--new': displayInfo.get(vehicle.id) != null
                        && displayInfo.get(vehicle.id).newMessagesCount > 0}"></span>
      </div>
      <div class="message__details" (click)="openChat(vehicle)">
        <div class="message__author">
          <span class="author text-capitalize">{{vehicle.plateNumber}}</span>
          <span class="driver-name" *ngIf="vehicle.driverName">({{vehicle.driverName}})</span>
        </div>
        <span class="message__timestamp">
          {{displayInfo.get(vehicle.id)?.lastSentOn}}
        </span>
        <div class="message__text">
          {{displayInfo.get(vehicle.id)?.lastMessage}}
        </div>
      </div>
      <a class="icon-action scan-doc-button"
         (click)="openScannedDocs(vehicle)">
        <i class="ico-paperclip "></i>
      </a>
    </div>
  </div>
  <div class="chat__filter chat-list__filter form--soft d-flex">
    <div class="input-group">
      <input class="form-control" type="text" (keyup)="filterVehicles($event.target.value)">
      <div class="chat-header__button"><i class="fa fa-search"></i></div>
    </div>
    <button class="chat-header__button" (click)="markAllRead()">
      {{'chat.list.readAll' | translate}}
    </button>
  </div>
</div>

