import {
  AbstractControl,
  FormControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import {EcoDefaults} from '../pages/client/reports/eco/vehicle-group-configs/eco-defaults';

const INTEGER_MAX = 2147483647;
const INTEGER_MIN = -2147483647;
const MINUTES_IN_WEEK = 10080;

export function emailValidator(control: UntypedFormControl): { [key: string]: any } {
  let emailRegexp = new RegExp('[a-z0-9._%+-]+@(([0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3})|' +
    '(([a-zA-Z-0-9]{2,}.)+[a-zA-Z]{2,}))$');
  if (control.value && !emailRegexp.test(control.value)) {
    return {invalidEmail: true};
  }
}

export function fullNameValidator(control: UntypedFormControl): { [key: string]: any } {
  let fullNameRegexp = new RegExp('^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮ' +
    'ŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð0-9 +]*$');
  if (control.value && !fullNameRegexp.test(control.value)) {
    return {invalidFullName: true};
  }
}

export function onlyLatinLettersValidator(control: UntypedFormControl): { [key: string]: any } {
  let onlyLatinSymbolsRegexp = new RegExp('^[A-Za-z]+$');
  if (control.value && !onlyLatinSymbolsRegexp.test(control.value)) {
    return {invalidLatin: true};
  }
}

export function numberValidator(control: UntypedFormControl): { [key: string]: any } {
  let numberRegexp = /^[0-9]+$/u;
  if (control.value && !numberRegexp.test(control.value)) {
    return {invalidNumber: true};
  }
}

export function titleValidator(control: UntypedFormControl): { [key: string]: any } {
  let titleRegexp =
    new RegExp('^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿý' +
      'żźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð ,.\'"-]+$');
  if (control.value && !titleRegexp.test(control.value)) {
    return {invalidTitle: true};
  }
}

export function partnerTitleValidator(control: UntypedFormControl): { [key: string]: any } {
  let titleRegexp =
    new RegExp('^[a-zA-Z0-wа-яА-Я-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿý' +
      'żźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð& ,.\'"-]+$');
  if (control.value && !titleRegexp.test(control.value)) {
    return {invalidTitle: true};
  }
}

export function codeValidator(control: UntypedFormControl): { [key: string]: any } {
  let codeRegexp = /^[A-Z0-9 +*-]{7,17}$/u;
  if (control.value && !codeRegexp.test(control.value)) {
    return {invalidCode: true};
  }
}

export function coordinatesValidator(control: UntypedFormControl): { [key: string]: any } {
  let codeRegexp = new RegExp('^[ |\\t]*[-]?[0-9]{1,3}[.][0-9]{1,14}[,][ |\\t]*[-]?[0-9]{1,3}[.][0-9]{1,14}[ |\\t]*$', 'im');
  if (control.value && !codeRegexp.test(control.value)) {
    return {invalidCoordinates: true};
  }
}

export function ordersCoordinatesValidator(control: UntypedFormControl): { [key: string]: any } {
  let cordRegexp = new RegExp('^[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?),\\s*[-+]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)$')
  if (control.value && !cordRegexp.test(control.value)) {
    return {invalidOrdersCoordinates: true};
  }
}

export function phoneNumberValidator(control: UntypedFormControl): { [key: string]: any } {
  let phoneNumberRegexp = new RegExp('^[\+]?[(]?[0-9]{1,3}[)]?[ -.()]{0,2}?[0-9]{2,4}[ -.()]{0,2}?[0-9]'
    + '{2,9}?[ -.()]{0,2}?[0-9]{0,5}?[ -.()]{0,2}?[0-9]{0,5}$', 'im');
  if (control.value && !phoneNumberRegexp.test(control.value)) {
    return {invalidPhoneNumber: true};
  }
}

export function phoneE164NumberValidator(control: UntypedFormControl): { [key: string]: any } {
  let phoneNumberRegexp = new RegExp(`^\\+[0-9]{10,12}$|^[0-9]{9,11}$`, 'im');
  if (control.value && !phoneNumberRegexp.test(control.value)) {
    return {invalidE164PhoneNumber: true};
  }
}

export function simPhoneNumberValidator(control: UntypedFormControl): { [key: string]: any } {
  let simPhoneNumberRegexp = new RegExp(`^\\+[0-9]{5,22}$|^[0-9]{5,22}$`, 'gm');
  if (control.value && !simPhoneNumberRegexp.test(control.value)) {
    return {invalidSimPhoneNumber: true};
  }
}

export function imeiValidator(control: UntypedFormControl): { [key: string]: any } {
  let imeiRegexp = /^[0-9]{15}$/u;
  if (control.value && !imeiRegexp.test(control.value)) {
    return {invalidImei: true};
  }
}

export function driverCardValidator(control: UntypedFormControl): { [key: string]: any } {
  let driverCardRegexp = /^[A-Z0-9]{1}[-A-Z0-9]{1}[A-Z0-9]{14}$/u;
  let ibuttonRegexp = /^[0-9]{18}$/u;
  if (!driverCardRegexp.test(control.value) && !ibuttonRegexp.test(control.value)) {
    return {invalidDriverCard: true};
  }
}

export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
  return (group: UntypedFormGroup) => {
    let password = group.controls[passwordKey];
    let repeatPassword = group.controls[passwordConfirmationKey];
    if (password.value !== repeatPassword.value) {
      return repeatPassword.setErrors({mismatchedPasswords: true});
    }
  };
}

export function passwordValidator(control: UntypedFormControl): { [key: string]: any } {
  let passwordRegexp = new RegExp('^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})');
  if (control.value && !passwordRegexp.test(control.value)) {
    return {invalidPassword: true};
  }
}

export function vehicleManufactureYearValidation(control: UntypedFormControl): { [key: string]: any } {
  // YYYY Validates year in range: (PRESENT YEAR - 100) - PRESENT YEAR
  let presentYear = moment().year();
  if (!(control.value >= presentYear - 100 && control.value <= presentYear)) {
    return {invalidVehicleManufactureYear: true};
  }
}

export function dateValidationWithTime(control: UntypedFormControl): { [key: string]: any } {
  // YYYY-MM-DD HH:MM Validates leap years, correct month lengths
  let dateTimeRegExp = new RegExp('^((\\d{2}(([02468][048])|([13579][26]))[-]?((((0?[13578])|(1[02]))[-]?' +
    '((0?[1-9])|([1-2][0-9])|(3[01])))|(((0?[469])|(11))[-]?((0?[1-9])|([1-2][0-9])|(30)))|(0?2[-]?((0?[1-9])|' +
    '([1-2][0-9])))))|(\\d{2}(([02468][1235679])|([13579][01345789]))[-]?((((0?[13578])|(1[02]))[-]?((0?[1-9])|' +
    '([1-2][0-9])|(3[01])))|(((0?[469])|(11))[-]?((0?[1-9])|([1-2][0-9])|(30)))|(0?2[-]?((0?[1-9])|(1[0-9])|' +
    '(2[0-8]))))))(\\s((([0-1]?[0-9])|([2][0-3]))\\:([0-5][0-9])))?$');
  if (control.value && !dateTimeRegExp.test(control.value)) {
    return {invalidDateTimeFormat: true};
  }
}

export function dateValidation(control: UntypedFormControl): { [key: string]: any } {
  // YYYY-MM-DD Validates leap years, correct month lengths
  let dateRegExp = new RegExp('^((((19[0-9][0-9])|(2[0-9][0-9][0-9]))([-])(0[13578]|10|12)([-])(0[1-9]|' +
    '[12][0-9]|3[01]))|(((19[0-9][0-9])|(2[0-9][0-9][0-9]))([-])(0[469]|11)([-])([0][1-9]|[12][0-9]|30))|' +
    '(((19[0-9][0-9])|(2[0-9][0-9][0-9]))([-])(02)([-])(0[1-9]|1[0-9]|2[0-8]))|(([02468][048]00)([-])(02)([-])(29))|' +
    '(([13579][26]00)([-])(02)([-])(29))|(([0-9][0-9][0][48])([-])(02)([-])(29))|(([0-9][0-9][2468][048])([-])' +
    '(02)([-])(29))|(([0-9][0-9][13579][26])([-])(02)([-])(29)))$');
  if (control.value && !dateRegExp.test(control.value)) {
    return {invalidDateFormat: true};
  }
}

export function timeValidation(control: UntypedFormControl): { [key: string]: any } {
  // HH:MM
  let timeRegExp = new RegExp('^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$');
  if (control.value && !timeRegExp.test(control.value)) {
    return {invalidTimeFormat: true};
  }
}

function validateDuration(fromName: string, tillName: string, maxDuration: number, error: any): any {
  return (group: UntypedFormGroup) => {
    let from = group.controls[fromName];
    let till = group.controls[tillName];
    if (from.value && till.value) {
      let diffInMinutes = moment(till.value).diff(moment(from.value), 'minutes');
      if (diffInMinutes > maxDuration) {
        till.setErrors(error);
      } else {
        till.setErrors(null);
      }
    }
  };
}

export function validateVideoSegmentDuration(fromName: string, tillName: string): any {
  return validateDuration(fromName, tillName, 10, {'period.segment-video': true});
}

export function validateVideoHistoryDuration(fromName: string, tillName: string): any {
  return validateDuration(fromName, tillName, 180, {'period.video': true});
}

export function validateTachoCountryReportDuration(fromName: string, tillName: string): any {
  return validateDuration(fromName, tillName, 44640, {'period.reports': true});
}

export function validateTrackDuration(fromName: string, tillName: string): any {
  return validateDuration(fromName, tillName, 132480, {'period.track': true});
}

export function validateReportsDuration(fromName: string, tillName: string): any {
  return validateDuration(fromName, tillName, 132480, {'period.reports': true});
}

export function validateFromTillDuration(fromName: string,
                                         tillName: string,
                                         maxDuration: number,
                                         errorTranslationKey: string): any {

  return validateDuration(fromName, tillName, maxDuration, {[errorTranslationKey]: true});
}

export function validateMax1HourDuration(fromName: string, tillName: string): any {
  return validateDuration(fromName, tillName, 60, {'period.one-hour': true});
}

export function validateMaxTwoMonths(fromName: string, tillName: string): any {
  return validateDuration(fromName, tillName, 90780, {'period.twoMonths': true});
}

export function validateMaxHalfYear(fromName: string, tillName: string): any {
  return validateDuration(fromName, tillName, 270719, {'period.halfYear': true});
}

export function validateMaxOneYear(fromName: string, tillName: string): any {
  return validateDuration(fromName, tillName, 528479, {'period.year': true});
}

export function validateTillLaterThanFrom(fromName: string, tillName: string): ValidatorFn {
  return (form: UntypedFormGroup): ValidationErrors | null => {
    let from = form.controls[fromName];
    let till = form.controls[tillName];
    return till.value < from.value ? {till: true} : null;
  };
}

export function validateOptionalTillLaterThanFrom(from: string, till: string): ValidatorFn {
  return (form: UntypedFormGroup): ValidationErrors | null => {
    let fromField = form.controls[from];
    let tillField = form.controls[till];
    if (!fromField || !tillField) {
      return null;
    }
    let fromNotEmpty = fromField.value != null && fromField.value !== '';
    let tillNotEmpty = tillField.value != null && tillField.value !== '';
    if (fromNotEmpty && tillNotEmpty && tillField.value < fromField.value) {
      return {fromLater: true}
    } else {
      return null;
    }
  };
}

export function validateTillNotLaterThan(fieldName: string, value: string): any {
  return (form: UntypedFormGroup) => {
    let field = form.controls[fieldName];
    if (moment(field.value).isAfter(value)) {
      field.setErrors({'period.future': true});
    }
  };
}

export function validateFieldTillLaterThanFrom(fromName: string, tillName: string): any {
  return (form: UntypedFormGroup) => {
    let from = form.controls[fromName];
    let till = form.controls[tillName];
    if (till.value != null && till.value !== '' && till.value < from.value) {
      till.setErrors({till: true})
    } else {
      till.setErrors(null)
    }
  };
}

export function validatePeriodFrom(fieldName: string, value: string): any {
  return (form: UntypedFormGroup) => {
    let field = form.controls[fieldName];
    if (moment(field.value).isBefore(value)) {
      field.setErrors({'period.before': true});
    }
  };
}

export function validatePeriodTill(fieldName: string, value: string): any {
  return (form: UntypedFormGroup) => {
    let field = form.controls[fieldName];
    if (moment(field.value).isAfter(value)) {
      field.setErrors({'period.after': true});
    }
  };
}

export function validateEitherRequired(first: string, second: string): ValidatorFn {
  return (form: UntypedFormGroup): ValidationErrors | null => {
    let firstField = form.controls[first];
    let secondField = form.controls[second];
    if (!firstField || !secondField) {
      return null;
    }
    if (firstField.value != null || secondField.value != null) {
      return null;
    } else {
      return {typeRequired: true}
    }
  };
}

export function validateEitherRequiredOrThirdIncludes(first: string,
                                                      second: string,
                                                      third: string,
                                                      thirdValues: string[]): ValidatorFn {

  return (form: UntypedFormGroup): ValidationErrors | null => {
    let firstField = form.controls[first];
    let secondField = form.controls[second];
    let thirdField = form.controls[third];
    if (!firstField || !secondField || !thirdField) {
      return null;
    }
    let isValid = firstField.value != null
      || secondField.value != null
      || thirdValues.includes(thirdField.value);
    if (isValid) {
      return null;
    } else {
      return {typeRequired: true}
    }
  };
}

export function requiredIfAnyFilled(fieldName: string, dependingFields: string[]): any {
  return (form: UntypedFormGroup) => {
    let valid = true;
    let validatingField = form.controls[fieldName];
    for (let depending of dependingFields) {
      let field = form.controls[depending];
      if (field && field.value != null && field.value !== '' && validatingField.value == null) {
        valid = false;
        break;
      }
    }
    if (valid) {
      validatingField.setErrors(null)
    } else {
      validatingField.setErrors({required: true})
    }
  };
}

export function priceRequired(control: UntypedFormControl): { [key: string]: any } {
  let priceExp = new RegExp('^\\d{1,8}(?:\\.\\d{1,2})?$');
  if (control.value && !priceExp.test(control.value)) {
    return {invalidPrice: true};
  }
}

export function validDouble(control: UntypedFormControl): { [key: string]: any } {
  let priceExp = new RegExp('^-?[0-9]\\d*(\\.\\d{1,3})?$');
  if (control.value && !priceExp.test(control.value)) {
    return {invalidDouble: true};
  }
}

export function validInteger(control: UntypedFormControl): { [key: string]: any } {
  if (control.value && !Number.isInteger(control.value)) {
    return {invalidInteger: true};
  }
  if (control.value <= INTEGER_MIN) {
    return {min: true};
  }
  if (control.value >= INTEGER_MAX) {
    return {max: true};
  }
}

export function validatePercentRange(control: UntypedFormControl): { [key: string]: any } {
  let range = new RegExp('^([0-9]{1,2}){1}(\\.[0-9]{1,2})?$');
  if (control.value && !range.test(control.value)) {
    return {invalidRange: true};
  }
}

export function percentageValidator(control: UntypedFormControl): { [key: string]: any } {
  if (control.value < 0 || control.value > 100) {
    return {wrongPercentage: true}
  }
}

export function bestGreaterValidator(): any {
  return (form: UntypedFormGroup) => {
    let best = form.controls['best'];
    let worst = form.controls['worst'];
    if (!(best.value > worst.value)) {
      best.setErrors({'bestNotGreater': true});
    }
  }
}

export function bestLesserValidator(): any {
  return (form: UntypedFormGroup) => {
    let best = form.controls['best'];
    let worst = form.controls['worst'];
    if (!(best.value < worst.value)) {
      best.setErrors({'bestNotLesser': true});
    }
  }
}

export function speedValidator(control: UntypedFormControl): { [key: string]: any } {
  if (control.value < 0 || control.value > EcoDefaults.MAX_SPEED || control.value % 1 !== 0) {
    return {wrongSpeed: true}
  }
}

export function rpmValidator(control: UntypedFormControl): { [key: string]: any } {
  if (control.value <= 0 || control.value > EcoDefaults.MAX_RPM || control.value % 1 !== 0) {
    return {wrongRPM: true}
  }
}

export function equalOrGreaterThanZero(control: UntypedFormControl): { [key: string]: any } {
  if (control.value < 0) {
    return {lessThanZero: true};
  }
}

export function choiceArrayBiggerThan(size: number): ValidatorFn {
  return (form: UntypedFormGroup): ValidationErrors | null => {
    let value = form.value;
    if (!value) {
      return null;
    }
    if (value.type === 'SELECTION') {
      let choices = value.choices;
      return (!choices || choices.length <= size) ? {'choiceArrayInvalid': true} : null;
    } else {
      return null;
    }
  }
}

export function averageSpeedValidator(control: UntypedFormControl): { [key: string]: any } {
  if (control.value !== null && (control.value < 10 || control.value > 100)) {
    return {wrongAverageSpeed: true}
  }
}

export function maxWeekDurationInMinutesValidator(control: UntypedFormControl): { [key: string]: any } {
  if (control.value !== null && (control.value < 0 || control.value > MINUTES_IN_WEEK)) {
    return {wrongAverageSpeed: true}
  }
}

export function geozoneOrTachoRequired(geozoneControl: string, tachoActivityControl: string): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {
    let geozone = form.get(geozoneControl).value;
    let activity = form.get(tachoActivityControl).value;
    return geozone != null || activity != null
      ? null
      : {geozoneOrActivityRequired: true};
  };
}

export function tachoActivityAndDurationRequired(tachoActivityControl: string, tachoDurationControl: string): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {
    let activity = form.get(tachoActivityControl).value;
    let duration = form.get(tachoDurationControl).value;
    return (duration != null && activity != null) || (duration == null && activity == null)
      ? null
      : {tachoActivityDurationRequired: true};
  };
}

export function fifteenMinuteInterval(control: UntypedFormControl): { [key: string]: any } {
  const value: string = control.value;
  if (value?.length === 16) {
    let valid = value.endsWith('00') || value.endsWith('15') || value.endsWith('30') || value.endsWith('45');
    return !valid ? {fifteenMinuteInterval: true} : null;
  }
}

export function validShellExpiry(control: FormControl<string>): { [key: string]: any } {
  const value = control.value;
  if (value?.length === 8) {
    let validDate = moment(value).isValid();
    return !validDate ? {invalidShellDate: true} : null;
  }
}

export function spacingValidation(control: UntypedFormControl): { [key: string]: any } {
  const isWhitespace = ((control.value || '').indexOf(' ') >= 0);
  if (isWhitespace) {
    return {invalidSpacing: true}
  }
}

export function swiftLengthValidation(control: UntypedFormControl): { [key: string]: any } {
  if (control.value != null) {
    if (control.value.length < 8 || control.value.length > 11) {
      return {invalidSwiftLength: true};
    }
  }
}

export function temperatureRange(additionalOptions: UntypedFormArray,
                                 optionToCompareId: number,
                                 isLowerValue: boolean): { [key: string]: any } {

  return (form: AbstractControl): ValidationErrors | null => {
    let result = additionalOptions.controls.filter(it => it.value.optionId === optionToCompareId);
    if (result && result.length > 0) {
      let value = form.value;
      let comparingValue = result[0].value.value;
      let temperatureRegex = new RegExp('^$|^[-]?([0-9]*\\.[0-9]+|[0-9]+)$');
      let valid = temperatureRegex.test(value);
      if (value && !valid) {
        return {wrongTemperature: true};
      }
      if (comparingValue != null && valid && value != null) {
        let validInput = isLowerValue
          ? Number(value) < Number(comparingValue)
          : Number(value) > Number(comparingValue);
        if (!validInput) {
          return isLowerValue ? {temperatureTooHigh: true} : {temperatureTooLow: true};
        }
      }
    }
    return null;
  }
}
