export class EcoDefaults {

  public static BRAKING_STOPING_BEST = 2.0;
  public static BRAKING_STOPING_WORST = 5.0;
  public static BRAKING_STOPING_PCT = 5.0;
  public static ROLLING_BEST = 28.0;
  public static ROLLING_WORST = 18.0;
  public static ROLLING_PCT = 25.0;
  public static ECONOMY_BEST = 0.1;
  public static ECONOMY_WORST = 1.0;
  public static ECONOMY_PCT = 5.0;
  public static LOAD_BEST = 2.5;
  public static LOAD_WORST = 5.0;
  public static LOAD_PCT = 25.0;
  public static OVERSPEED_BEST = 1.0;
  public static OVERSPEED_WORST = 5.0;
  public static OVERSPEED_PCT = 5.0;
  public static CRUISE_DISTANCE_BEST = 80.0;
  public static CRUISE_DISTANCE_WORST = 50.0;
  public static CRUISE_DISTANCE_PCT = 10.0;
  public static CRUISE_FUEL_BEST = 4.0;
  public static CRUISE_FUEL_WORST = 0.0;
  public static CRUISE_FUEL_PCT = 10.0;
  public static IDLING_BEST = 5.0;
  public static IDLING_WORST = 10.0;
  public static IDLING_PCT = 15.0;
  public static DEFAULT_MAX_SPEED = 5;
  public static DEFAULT_RPM_LIMIT = 1400;
  public static DEFAULT_SPEED_LIMIT = 84;
  public static MISTAKE_COLOR = '#D41400';
  public static VALID_COLOR = '#299D0F';
  public static MAX_SPEED = 150;
  public static MAX_RPM = 6000;

}
