import {Injectable} from '@angular/core';
import {MessageEntry} from '../message.entry';
import {Observable} from 'rxjs';
import {NewMessageForm} from './new-message.form';
import {ChatUpdateEntry} from './chat-update.entry';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {delay, map, retryWhen, take} from 'rxjs/operators';
import {MessagesFilter} from './messages.filter';
import {ChatMarkReadEntry} from './chat-mark-read.entry';
import {InitMessagesResponse} from './init-messages-response';
import {SentMessageResponse} from '../model/SentMessageResponse';

@Injectable()
export class ChatMessagesService {

  constructor(private http: HttpClient) {}

  public saveMessage(vehicleId: number, messageText: string): Observable<SentMessageResponse> {
    let form = new NewMessageForm(vehicleId, messageText);
    return this.http.post('/api/messages', form).pipe(map(res => <SentMessageResponse>res));
  }

  public fetchInitMessages(vehicleId: number): Observable<InitMessagesResponse> {
    return this.http.get<InitMessagesResponse>(`/api/messages/init?vehicleId=${vehicleId}`)
      .pipe(retryWhen(errors => errors.pipe(delay(1000), take(3))));
  }

  public fetchMessages(vehicleId: number, lastMessage: number): Observable<MessageEntry[]> {
    return this.http.get(`/api/messages` +
      `?vehicleId=${vehicleId}` +
      `&lastMessage=${lastMessage}`)
      .pipe(map(res => <MessageEntry[]>res));
  }

  public addMessages(vehicleId: number, lastMessageId: number): Observable<MessageEntry[]> {
    return this.http.get(`/api/messages/add` +
      `?vehicleId=${vehicleId}` +
      `&lastMessage=${lastMessageId}`)
      .pipe(map(res => <MessageEntry[]>res));
  }

  public updateChat(vehicleId: number, lastShown: number): Observable<ChatUpdateEntry> {
    return this.http.get(`/api/messages/update-chat?vehicleId=${vehicleId}&lastShown=${lastShown}`)
      .pipe(map(res => <ChatUpdateEntry>res));
  }

  public markAsUnread(messages: ChatMarkReadEntry[]): Observable<any> {
    return this.http.post('/api/messages/mark-as-unread', messages);
  }

  public saveMultipartMessage(vehicleId: number, message: string, file: any): Observable<MessageEntry> {
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(`/api/chat-files/multipart?vehicleId=${vehicleId}&message=${message}`, formData, {
      headers: new HttpHeaders().append('Accept', 'application/json')
    })
      .pipe(map(res => <MessageEntry>res));
  }


  public filterMessages(vehicleId: number, filter: MessagesFilter): Observable<MessageEntry[]> {
    return this.http.post(`/api/messages/filter?vehicleId=${vehicleId}`, filter)
      .pipe(map(res => <MessageEntry[]>res));
  }

  public loadFiltered(vehicleId: number, date: string): Observable<MessageEntry[]> {
    return this.http.get(`/api/messages/filter/load?vehicleId=${vehicleId}&date=${date}`)
      .pipe(map(res => <MessageEntry[]>res));
  }

  public getLastSeenMessage(vehicleId: number): Observable<number> {
    return this.http.get(`/api/messages/last-seen?vehicleId=${vehicleId}`)
      .pipe(map(res => <number>res));
  }

  public markMessagesAllAsRead(): Observable<void> {
    return this.http.get<void>(`/api/messages/mark-all-read`)
  }
}
