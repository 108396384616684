import {Injectable} from '@angular/core';
import {Observable, of, Subject, tap} from 'rxjs';
import {Profile} from '../model/profile';
import {IdValue} from '../model/id-value';
import {TextValue} from '../model/text-value';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SystemWarningEntry} from './system-warning.entry';
import {VehicleTrailer} from '../../pages/client/orders/orders/form/carrier/vehicle-trailer';
import {UserWsInfo} from '../ws/model/user-ws-info';
import {CountriesIsoCodeEntry} from '../../pages/client/tasks/pro-tasks-manager/form/properties/parameters/model/countries-iso-code.entry';
import {TypedVehicle} from '../../pages/client/orders/orders/form/carrier/typed-vehicle';

@Injectable()
export class GlobalService {

  private newLatestSubsSource = new Subject<void>();
  private newLatestSubs$ = this.newLatestSubsSource.asObservable();

  private nearestVehicleClickSource = new Subject<number>();
  private nearestVehicleClick$ = this.nearestVehicleClickSource.asObservable();

  private proTasksActiveSource = new Subject<boolean>();
  private proTasksActive$ = this.proTasksActiveSource.asObservable();

  private oldTasksActiveSource = new Subject<boolean>();
  private oldTasksActive$ = this.oldTasksActiveSource.asObservable();

  private resetSearchValueSource = new Subject<void>();
  private resetSearchValue$ = this.resetSearchValueSource.asObservable();

  private cachedLogo: Blob;

  constructor(private http: HttpClient) {
  }

  public getLoginDetails(): Observable<Profile> {
    return this.http.get<Profile>('/api/auth/on-login');
  }

  public getLoggedProfile(): Observable<Profile> {
    return this.http.get<Profile>('/api/auth/logged-account');
  }

  public partners(): Observable<IdValue<string>[]> {
    return this.http.get<IdValue<string>[]>('/api/global/partners');
  }

  public vehicles(): Observable<IdValue<string>[]> {
    return this.http.get<IdValue<string>[]>('/api/global/vehicles');
  }

  public noTrailers(): Observable<IdValue<string>[]> {
    return this.http.get<IdValue<string>[]>('/api/global/no-trailers');
  }

  public typedVehicles(): Observable<TypedVehicle[]> {
    return this.http.get<TypedVehicle[]>('/api/global/typed-vehicles');
  }

  public vehicle(id: number): Observable<IdValue<string>> {
    return this.http.get<IdValue<string>>(`/api/global/vehicle/${id}`);
  }

  public drivers(): Observable<IdValue<string>[]> {
    return this.http.get<IdValue<string>[]>('/api/global/drivers');
  }

  public driverCards(): Observable<TextValue<string>[]> {
    return this.http.get<TextValue<string>[]>('/api/global/driverCards');
  }

  public clientEmployees(): Observable<IdValue<string>[]> {
    return this.http.get<IdValue<string>[]>('/api/global/client-employees');
  }

  public clientSupervisorsEmployees(): Observable<IdValue<string>[]> {
    return this.http.get<IdValue<string>[]>('/api/global/client-supervisors-employees');
  }

  public getLoggedClientEmployee(): Observable<IdValue<string>> {
    return this.http.get<IdValue<string>>('/api/global/client-employee');
  }

  public partnerEmployees(): Observable<IdValue<string>[]> {
    return this.http.get<IdValue<string>[]>('/api/global/partner-employees');
  }

  public clientDepartments(): Observable<IdValue<string>[]> {
    return this.http.get<IdValue<string>[]>('api/global/client-departments');
  }

  public now(): Observable<any> {
    return this.http.get('/api/global/now', {responseType: 'text'});
  }

  public hasFuelCounter(): Observable<boolean> {
    return this.http.get<boolean>('/api/global/has-fuel-counter');
  }

  public getCustomerSupport(): Observable<string> {
    return this.http.get('/api/global/support', {responseType: 'text', headers: {skip: 'true'}});
  }

  public newLatestSubscription(): void {
    this.newLatestSubsSource.next(); // todo ignas remov latest
  }

  public newLatestSubs(): Observable<void> {
    return this.newLatestSubs$; // todo ignas remov latest
  }

  public getEnvironment(): Observable<string> {
    return this.http.get('/api/global/environment', {responseType: 'text'});
  }

  public getPOIInBounds(polyline: string, distance: string): Observable<string> {
    return this.http.post<string>('/api/poi/stations-near-route?&distance=' + distance, polyline);
  }

  public getCountries(): Observable<IdValue<string>[]> {
    return this.http.get<IdValue<string>[]>('/api/global/countries');
  }

  public getCountriesIsoCode(): Observable<CountriesIsoCodeEntry[]> {
    return this.http.get<CountriesIsoCodeEntry[]>('/api/global/countries-iso-code', {headers: {skip: 'true'}});
  }

  public getCurrencies(): Observable<IdValue<string>[]> {
    return this.http.get<IdValue<string>[]>('/api/global/currencies');
  }

  public nearestVehicleClick(vehicleId: number) {
    this.nearestVehicleClickSource.next(vehicleId);
  }

  public nearestVehicleClicked(): Observable<number> {
    return this.nearestVehicleClick$;
  }

  public checkForSystemWarning(): Observable<SystemWarningEntry> {
    return this.http.get<SystemWarningEntry>('/api/system-warning', {headers: {skip: 'true'}});
  }

  public trailers(): Observable<VehicleTrailer[]> {
    return this.http.get<VehicleTrailer[]>('/api/global/trailers')
  }

  public notifyAboutProTasks(active: boolean) {
    this.proTasksActiveSource.next(active);
  }

  public notifiedAboutProTasks(): Observable<boolean> {
    return this.proTasksActive$;
  }

  public notifyAboutOldTasks(active: boolean) {
    this.oldTasksActiveSource.next(active);
  }

  public notifiedAboutOldTasks(): Observable<boolean> {
    return this.oldTasksActive$;
  }

  public userAlarmWsInfo(): Observable<UserWsInfo> {
    return this.http.get<UserWsInfo>('/api/global/alarm-ws/user-info', {headers: {skip: 'true'}})
  }

  public userChatWsInfo(): Observable<UserWsInfo> {
    return this.http.get<UserWsInfo>('/api/global/chat-ws/user-info', {headers: {skip: 'true'}})
  }

  public userAssetsStateWsInfo(): Observable<UserWsInfo> {
    return this.http.get<UserWsInfo>('/api/global/assets-state-ws/user-info', {headers: {skip: 'true'}})
  }

  public ownedVehicles(): Observable<IdValue<string>[]> {
    return this.http.get<IdValue<string>[]>('/api/global/owned-vehicles');
  }

  public getClientZoneId(): Observable<string> {
    return this.http.get('/api/global/client-zone', {responseType: 'text'});
  }

  public getSellerLogo(): Observable<Blob> {
    if (this.cachedLogo == null) {
      return this.http.get('/api/auth/seller-logo', { responseType: 'blob' })
        .pipe(tap(blob => this.cachedLogo = blob));
    }
    return of(this.cachedLogo);
  }

  public getClientSubClients(): Observable<IdValue<string>[]> {
    return this.http.get<IdValue<string>[]>('/api/global/client-sub-clients');
  }

  public triggerSearchValueReset() {
    this.resetSearchValueSource.next();
  }

  public resetSearchValue(): Observable<void> {
    return this.resetSearchValue$;
  }

  public getTimeZones(): Observable<string[]> {
    return this.http.get<string[]>('/api/global/zones');
  }

}
