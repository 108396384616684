import {JwtHelperService} from '@auth0/angular-jwt';
import {canManageServices, isClient, isPartner, Profile, Role} from '../model/profile';
import {MapConfigsForm} from '../../pages/client/settings/global-config/map-configs/map-configs.form';
import {TasksConfigsForm} from '../../pages/client/settings/global-config/tasks-configs/model/tasks-configs.form';
import {
  TrackPopupConfigsForm
} from '../../pages/client/settings/global-config/track-popup-configs/model/track-popup-configs.form';
import {PopupConfigsForm} from '../../pages/client/settings/global-config/popup-configs/popup-configs.form';
import {GanttConfigsForm} from '../../pages/client/planning/gantt/model/gantt-configs-form';
import {
  TachoTableConfigsForm
} from '../../pages/client/settings/global-config/tacho-table-configs/form/tacho-table-configs.form';
import {ChatConfigsForm} from '../../pages/client/settings/global-config/chat-configs/model/chat-configs.form';
import {KeyboardCommands} from '../../pages/client/settings/global-config/chat-configs/model/keyboard-commands';
import {WarningsConfigsForm} from '../../pages/client/settings/global-config/warnings-configs/warnings-configs.form';
import {CookieService} from 'ngx-cookie';
import {SELECTED_POI} from '../global/cookies.constants';
import {ControlPanelSettingsEntry} from '../model/control-panel-settings.entry';
import {EnumValues} from 'enum-values';
import {allSortedTaskTypes, TaskTypes} from '../../pages/client/tasks/tasks-manager/task-form/task-types';
import {Appearance} from '../../pages/shared/profile/model/appearance';
import {MessageSortType} from '../component/chat/model/message-sort.type';
import {SelectedChart} from '../../pages/client/vehicles/vehicle-track/can-chart/can-chart.component';

export class AuthStorage {

  private static readonly ACCESS_TOKEN = 'fhw_access_token';
  private static readonly REFRESH_TOKEN = 'fhw_refresh_token';
  private static readonly IS_PASSWORD_EXPIRED = 'fhw_password_expired';

  private static readonly USER_ROLE = 'fhw_user_role';
  private static readonly USER_MODULES = 'fhw_user_modules';
  private static readonly SUBCONTRACTOR = 'fhw_is_subcontractor';
  private static readonly ACCOUNT_ID = 'fhw_account_id';
  private static readonly LOGGED_ACCOUNT_FULL_NAME = 'fhw_user_full_name';
  private static readonly USER_APPEARANCE = 'fhw_user_appearance';
  private static readonly USER_GROUP = 'fhw_user_group';
  private static readonly USER_DEPARTMENT = 'fhw_user_department';
  private static readonly IS_CRT_PARTNER = 'fhw_crt_partner';
  private static readonly IS_FORBIDDEN_MANAGE_TASKS = 'fhw_forbidden_manage_tasks';
  private static readonly IS_LKW_WALTER_ACTIVE = 'fhw_lkw_walter_active';
  private static readonly IS_UNVERO_ACTIVE = 'fhw_unvero_active';
  private static readonly IS_BROWSER_RESTRICTIONS_WARNING = 'fhw_browser_restrictions_warning';
  private static readonly OLD_MESSAGES = 'fhw_old_messages';
  private static readonly CAN_CHART_SELECTION = 'fh-can-chart-selection';
  private static readonly CAN_CHART_SELECTION_FREEZER = 'fh-can-chart-selection-freezer';

  private static readonly CONTROL_PANEL = 'fhw_control_panel';
  private static readonly TASK_MANAGING = 'fhw_task_managing';
  private static readonly MAP_CONFIGS = 'fhw_map_configs';
  private static readonly WARNINGS_CONFIGS = 'fhw_warnings_configs';
  private static readonly POPUP_CONFIGS = 'fhw_info_window_configs';
  private static readonly TASKS_CONFIGS = 'fhw_tasks_planner_configs';
  private static readonly TASKS_TYPES_CONFIGS = 'fhw_tasks_types_configs';
  private static readonly TRACK_POPUP_CONFIGS = 'fhw_track_info_window_configs';
  private static readonly TACHO_CONFIGS = 'fhw_tacho_table_configs';
  private static readonly FHW_USERS_VEHICLE_GROUPS = 'fhw_users_vehicle_groups';
  private static readonly CLIENT_ID = 'fhw_client_id';
  private static readonly PLANNING_CONFIGS = 'fhw_planning_configs';
  private static readonly SELECTED_CALENDARS = 'fhw_selected_calendars';
  private static readonly CHAT_CONFIGS = 'fhw_chat_configs';
  private static readonly CLIENT_HQ = 'fhw_client_hq';

  private static readonly MIN_TOKEN_VALIDITY_SEC = 60;

  private static targetUrl: string;

  private constructor() {
  }

  //-------------------------- Authentication

  public static persistTokens(accessToken: string, refreshToken: string): void {
    localStorage.setItem(AuthStorage.ACCESS_TOKEN, accessToken);
    localStorage.setItem(AuthStorage.REFRESH_TOKEN, refreshToken);
  }

  public static setAccessToken(accessToken: string): void {
    localStorage.setItem(AuthStorage.ACCESS_TOKEN, accessToken);
  }

  public static clearTokens(): void {
    localStorage.removeItem(AuthStorage.ACCESS_TOKEN);
    localStorage.removeItem(AuthStorage.REFRESH_TOKEN);
  }

  public static accessToken(): string {
    return localStorage.getItem(AuthStorage.ACCESS_TOKEN);
  }

  public static refreshToken(): string {
    return localStorage.getItem(AuthStorage.REFRESH_TOKEN);
  }

  public static isAccessTokenValid(): boolean {
    let token = AuthStorage.accessToken();
    return token != null && !new JwtHelperService().isTokenExpired(token, AuthStorage.MIN_TOKEN_VALIDITY_SEC);
  }

  public static isRefreshTokenValid(): boolean {
    let token = AuthStorage.refreshToken();
    return token != null;
  }

  public static persistPasswordExpiration(passwordExpired: boolean) {
    localStorage.setItem(AuthStorage.IS_PASSWORD_EXPIRED, JSON.stringify(passwordExpired));
  }

  public static isPasswordExpired(): boolean {
    let isPasswordExpired = localStorage.getItem(AuthStorage.IS_PASSWORD_EXPIRED);
    return isPasswordExpired != null && isPasswordExpired !== 'undefined' ? JSON.parse(isPasswordExpired) : null;
  }

  public static removePasswordExpiration() {
    localStorage.removeItem(AuthStorage.IS_PASSWORD_EXPIRED);
  }

  //-------------------------- Login

  public static updateUserSettings(profile: Profile, updateConfigs: boolean, cookieService?: CookieService): void {
    this.updateAccountInfo(profile);
    this.updateGeneralEmployeeInfo(profile);
    if (updateConfigs) {
      this.updateEmployeeConfigs(profile);
      this.clearUpdatableCookies(cookieService);
    }
  }

  private static updateAccountInfo(profile: Profile): void {
    this.persistUserRole(profile);
    this.persistLoggedId(profile);
    this.saveLoggedAccountFullName(profile.fullName);
  }

  private static updateGeneralEmployeeInfo(profile: Profile): void {
    this.persistUserGroup(profile.vehicleGroup);
    this.persistIsContractor(profile);
    this.persistUserDepartment(profile.department);
    this.persistIsCrtPartner(profile.crtPartner);
    this.persistIsForbiddenManageTasks(profile.forbiddenManageTasks);
    this.persistIsLKWActive(profile.lkwActive);
    this.persistIsUnveroActive(profile.unveroActive);
    this.persistClientHQ(profile.clientHQ);
  }

  private static updateEmployeeConfigs(profile: Profile): void {
    this.saveChatConfigs(profile.chatConfigs ?? ChatConfigsForm.defaultValues());
    this.saveMapConfigs(profile.mapConfigs ?? MapConfigsForm.defaultValues(false));
    this.savePopupConfigs(profile.popupConfigs ?? PopupConfigsForm.defaultValues());
    this.saveTachoConfigs(profile.tachoConfigs ?? TachoTableConfigsForm.defaultValues());
    this.saveTasksConfigs(profile.tasksConfigs ?? TasksConfigsForm.defaultValues(false));
    this.saveTrackPopupConfigs(profile.trackPopupConfigs ?? TrackPopupConfigsForm.defaultValues());
    this.saveWarningsConfigs(profile.warningsConfigs ?? WarningsConfigsForm.defaultValues());
    this.saveControlPanelSettings(profile.controlPanelSettings ?? ControlPanelSettingsEntry.defaultValues(false));
  }

  private static clearUpdatableCookies(cookieService: CookieService): void {
    if (cookieService) {
      cookieService.remove(SELECTED_POI);
    }
  }

  //-------------------------- Account

  public static persistUserRole(loggedUser: Profile): void {
    localStorage.setItem(AuthStorage.USER_ROLE, JSON.stringify(loggedUser.role));
    localStorage.setItem(AuthStorage.USER_MODULES, JSON.stringify(loggedUser.modules));
  }

  public static getUserRole(): Role {
    let role: string = <string>(localStorage.getItem(AuthStorage.USER_ROLE));
    return role ? JSON.parse(role) : null;
  }

  public static getModules(): string[] {
    let modules: string = <string>(localStorage.getItem(AuthStorage.USER_MODULES));
    return modules ? JSON.parse(modules) : null;
  }

  public static isLoggedClientEmployee(): boolean {
    return isClient(AuthStorage.getUserRole().toString());
  }

  public static isLoggedPartnerEmployee(): boolean {
    return isPartner(AuthStorage.getUserRole().toString());
  }

  public static canManageServices(): boolean {
    return canManageServices(AuthStorage.getUserRole().toString());
  }

  public static persistLoggedId(loggedUser: Profile): void {
    if (loggedUser.id) {
      localStorage.setItem(AuthStorage.ACCOUNT_ID, JSON.stringify(loggedUser.id))
    }
  }

  public static getLoggedId(): number {
    return <number>JSON.parse(localStorage.getItem(AuthStorage.ACCOUNT_ID));
  }

  public static saveLoggedAccountFullName(fullName: string) {
    localStorage.setItem(AuthStorage.LOGGED_ACCOUNT_FULL_NAME, JSON.stringify(fullName));
  }

  public static getLoggedAccountFullName(): string {
    let value = localStorage.getItem(AuthStorage.LOGGED_ACCOUNT_FULL_NAME);
    return value ? JSON.parse(value) : null;
  }

  public static getAppearance(): Appearance {
    let value = localStorage.getItem(AuthStorage.USER_APPEARANCE);
    return value ? JSON.parse(value) : Appearance.defaultValues();
  }

  public static setAppearance(appearance: Appearance): void {
    localStorage.setItem(AuthStorage.USER_APPEARANCE, JSON.stringify(appearance));
  }

  //-------------------------- Employee

  public static persistUserGroup(vehicleGroup: any): void {
    if (vehicleGroup) {
      localStorage.setItem(AuthStorage.USER_GROUP, JSON.stringify(vehicleGroup))
    }
  }

  public static getUserGroup(): any {
    let vehicleGroup = localStorage.getItem(AuthStorage.USER_GROUP);
    return vehicleGroup && vehicleGroup !== 'undefined' ? JSON.parse(vehicleGroup) : null;
  }

  public static removeUserGroup(): void {
    localStorage.removeItem(AuthStorage.USER_GROUP);
  }

  public static persistUserDepartment(departmentId: number): void {
    localStorage.setItem(AuthStorage.USER_DEPARTMENT, JSON.stringify(departmentId));
  }

  public static getUserDepartment(): number {
    let departmentId = localStorage.getItem(AuthStorage.USER_DEPARTMENT);
    return departmentId && departmentId !== 'undefined' ? JSON.parse(departmentId) : null;
  }

  public static persistIsCrtPartner(crtPartner: boolean): void {
    localStorage.setItem(AuthStorage.IS_CRT_PARTNER, JSON.stringify(crtPartner));
  }

  public static isCrtPartner(): boolean {
    let crtPartner = localStorage.getItem(AuthStorage.IS_CRT_PARTNER);
    return crtPartner && crtPartner !== 'undefined' ? JSON.parse(crtPartner) : null;
  }

  public static persistIsContractor(loggedUser: Profile): void {
    if (loggedUser.contractor != null) {
      localStorage.setItem(AuthStorage.SUBCONTRACTOR, JSON.stringify(loggedUser.contractor));
    }
  }

  public static isLoggedClientContractor(): boolean {
    let contractor = localStorage.getItem(AuthStorage.SUBCONTRACTOR);
    return contractor && contractor !== 'undefined' ? <boolean>JSON.parse(contractor) : null;
  }

  public static persistIsForbiddenManageTasks(forbiddenManageTasks: boolean): void {
    localStorage.setItem(AuthStorage.IS_FORBIDDEN_MANAGE_TASKS, JSON.stringify(forbiddenManageTasks));
  }

  public static isForbiddenManageTasks(): boolean {
    let forbiddenManageTasks = localStorage.getItem(AuthStorage.IS_FORBIDDEN_MANAGE_TASKS);
    return forbiddenManageTasks && forbiddenManageTasks !== 'undefined' ? JSON.parse(forbiddenManageTasks) : null;
  }

  public static persistIsLKWActive(lkwActive: boolean): void {
    localStorage.setItem(AuthStorage.IS_LKW_WALTER_ACTIVE, JSON.stringify(lkwActive));
  }

  public static isLKWActive(): boolean {
    let lkwActive = localStorage.getItem(AuthStorage.IS_LKW_WALTER_ACTIVE);
    return lkwActive != null && lkwActive !== 'undefined' ? JSON.parse(lkwActive) : false;
  }

  public static persistIsUnveroActive(lkwActive: boolean): void {
    localStorage.setItem(AuthStorage.IS_UNVERO_ACTIVE, JSON.stringify(lkwActive));
  }

  public static isUnveroActive(): boolean {
    let lkwActive = localStorage.getItem(AuthStorage.IS_UNVERO_ACTIVE);
    return lkwActive != null && lkwActive !== 'undefined' ? JSON.parse(lkwActive) : false;
  }

  public static persistIsBrowserRestrictionsWarning(showWarning: boolean): void {
    localStorage.setItem(AuthStorage.IS_BROWSER_RESTRICTIONS_WARNING, JSON.stringify(showWarning));
  }

  public static isBrowserRestrictionsWarning(): boolean {
    let warning = localStorage.getItem(AuthStorage.IS_BROWSER_RESTRICTIONS_WARNING);
    return warning != null && warning !== 'undefined' ? JSON.parse(warning) : null;
  }

  public static persistVehiclesMessages(messages: Map<number, string>): void {
    localStorage.setItem(AuthStorage.OLD_MESSAGES, JSON.stringify([...messages.entries()]));
  }

  public static getVehiclesMessages(): Map<number, string> {
    let content = localStorage.getItem(AuthStorage.OLD_MESSAGES);
    return content != null && content !== 'undefined'
      ? new Map<number, string>(JSON.parse(content))
      : new Map<number, string>();
  }

  //-------------------------- Employee configs

  public static saveControlPanelSettings(data: ControlPanelSettingsEntry) {
    localStorage.setItem(AuthStorage.CONTROL_PANEL, JSON.stringify(data));
  }

  public static getControlPanelSettings(): ControlPanelSettingsEntry {
    let controlPanel: string = <string>(localStorage.getItem(AuthStorage.CONTROL_PANEL));
    return controlPanel
      ? JSON.parse(controlPanel)
      : ControlPanelSettingsEntry.defaultValues(this.isLoggedClientEmployee());
  }

  //TODO: remove???
  public static saveTaskManagingSettings(data: any) {
    localStorage.setItem(AuthStorage.TASK_MANAGING, JSON.stringify(data));
  }

  public static savePopupConfigs(data: PopupConfigsForm) {
    localStorage.setItem(AuthStorage.POPUP_CONFIGS, JSON.stringify(data));
  }

  public static getPopupConfigs(): PopupConfigsForm {
    let popupConfigs: string = <string>(localStorage.getItem(AuthStorage.POPUP_CONFIGS));
    return popupConfigs ? JSON.parse(popupConfigs) : PopupConfigsForm.defaultValues();
  }

  public static saveMapConfigs(data: MapConfigsForm) {
    localStorage.setItem(AuthStorage.MAP_CONFIGS, JSON.stringify(data));
  }

  public static getMapConfigs(): MapConfigsForm {
    let mapConfigs: string = <string>(localStorage.getItem(AuthStorage.MAP_CONFIGS));
    return mapConfigs ? JSON.parse(mapConfigs) : MapConfigsForm.defaultValues(this.isLoggedClientEmployee());
  }

  public static saveWarningsConfigs(data: WarningsConfigsForm): void {
    localStorage.setItem(AuthStorage.WARNINGS_CONFIGS, JSON.stringify(data));
  }

  public static getWarningsConfigs(): WarningsConfigsForm {
    let warningsConfigs: string = <string>(localStorage.getItem(AuthStorage.WARNINGS_CONFIGS));
    return warningsConfigs ? JSON.parse(warningsConfigs) : WarningsConfigsForm.defaultValues();
  }

  public static isTwoDaysTailConfig(): boolean {
    let mapConfigs: string = <string>(localStorage.getItem(AuthStorage.MAP_CONFIGS));
    return mapConfigs ? JSON.parse(mapConfigs).showTwoDaysTail : false;
  }

  public static saveTasksConfigs(data: TasksConfigsForm) {
    localStorage.setItem(AuthStorage.TASKS_CONFIGS, JSON.stringify(data));
  }

  public static getTasksConfigs(): TasksConfigsForm {
    return localStorage.getItem(AuthStorage.TASKS_CONFIGS) !== 'undefined'
      ? JSON.parse(<string>localStorage.getItem(AuthStorage.TASKS_CONFIGS))
      : TasksConfigsForm.defaultValues(this.isLoggedClientEmployee());
  }

  public static saveTasksTypesConfigs(tasksTypes: string[]): void {
    if (tasksTypes) {
      let sortedTaskTypes = allSortedTaskTypes.filter(type => tasksTypes.includes(type));
      localStorage.setItem(AuthStorage.TASKS_TYPES_CONFIGS, JSON.stringify(sortedTaskTypes));
    }
  }

  public static getTasksTypesConfigs(): string[] {
    return localStorage.getItem(AuthStorage.TASKS_TYPES_CONFIGS) != null
      ? JSON.parse(<string>localStorage.getItem(AuthStorage.TASKS_TYPES_CONFIGS))
      : EnumValues.getNames(TaskTypes);
  }

  public static saveTrackPopupConfigs(data: TrackPopupConfigsForm) {
    localStorage.setItem(AuthStorage.TRACK_POPUP_CONFIGS, JSON.stringify(data));
  }

  public static getTrackPopupConfigs(): TrackPopupConfigsForm {
    let trackPopupConfigs: string = <string>(localStorage.getItem(AuthStorage.TRACK_POPUP_CONFIGS));
    return trackPopupConfigs
      ? JSON.parse(trackPopupConfigs)
      : TrackPopupConfigsForm.defaultValues();
  }

  public static saveTachoConfigs(data: TachoTableConfigsForm) {
    localStorage.setItem(AuthStorage.TACHO_CONFIGS, JSON.stringify(data));
  }

  public static getTachoConfigs(): TachoTableConfigsForm {
    return localStorage.getItem(AuthStorage.TACHO_CONFIGS) !== 'undefined'
      ? JSON.parse(<string>localStorage.getItem(AuthStorage.TACHO_CONFIGS))
      : TachoTableConfigsForm.defaultValues();
  }

  public static saveChatConfigs(data: ChatConfigsForm) {
    localStorage.setItem(AuthStorage.CHAT_CONFIGS, JSON.stringify(data));
  }

  public static getChatEnterConfigs(): string {
    let configs: string = <string>(localStorage.getItem(AuthStorage.CHAT_CONFIGS));
    return configs ? JSON.parse(configs).enter : KeyboardCommands.ENTER;
  }

  public static getChatShiftEnterConfigs(): string {
    let configs: string = <string>(localStorage.getItem(AuthStorage.CHAT_CONFIGS));
    return configs ? JSON.parse(configs).shiftEnter : KeyboardCommands.SHIFT_ENTER;
  }

  public static getChatMessageSortConfigs(): MessageSortType {
    let configs: string = <string>(localStorage.getItem(AuthStorage.CHAT_CONFIGS));
    return configs ? JSON.parse(configs).messageSort : MessageSortType.SENT_ON;
  }

  //-------------------------- General

  public static saveStateUrl(url: string): void {
    AuthStorage.targetUrl = url;
  }

  public static clearStateUrl(): void {
    AuthStorage.targetUrl = null;
  }

  public static stateUrl(): string {
    return AuthStorage.targetUrl;
  }

  public static saveUserVehicleGroups(vehicleGroups: any) {
    const updatedVehicleGroups = {
      ...AuthStorage.getAllUsersVehicleGroups(),
      [AuthStorage.getLoggedId()]: vehicleGroups
    };
    localStorage.setItem(AuthStorage.FHW_USERS_VEHICLE_GROUPS, JSON.stringify(updatedVehicleGroups))
  }

  private static getAllUsersVehicleGroups(): any {
    const vehicleGroup = localStorage.getItem(AuthStorage.FHW_USERS_VEHICLE_GROUPS);
    return vehicleGroup
      ? JSON.parse(vehicleGroup)
      : null;
  }

  public static getUserVehicleGroups(): any {
    const allVehicleGroup = AuthStorage.getAllUsersVehicleGroups();
    return allVehicleGroup && allVehicleGroup[AuthStorage.getLoggedId()]
      ? allVehicleGroup[AuthStorage.getLoggedId()]
      : [];
  }

  public static persistClientId(id: number): void {
    localStorage.setItem(AuthStorage.CLIENT_ID, JSON.stringify(id));
  }

  public static getClientId(): number {
    let value = localStorage.getItem(AuthStorage.CLIENT_ID);
    return value ? JSON.parse(value) : null;
  }

  public static persistSelectedCalendars(configs: number[]): void {
    localStorage.setItem(AuthStorage.SELECTED_CALENDARS, JSON.stringify(configs));
  }

  public static getSelectedCalendars(): number[] {
    let value = localStorage.getItem(AuthStorage.SELECTED_CALENDARS);
    return value ? JSON.parse(value) : null;
  }

  public static savePlanningConfigs(data: GanttConfigsForm) {
    localStorage.setItem(AuthStorage.PLANNING_CONFIGS, JSON.stringify(data));
  }

  public static getPlanningConfigs(): GanttConfigsForm {
    let configs: string = <string>(localStorage.getItem(AuthStorage.PLANNING_CONFIGS));
    return configs ? JSON.parse(configs) : null;
  }

  public static persistClientHQ(clientHQ: string): void {
    localStorage.setItem(AuthStorage.CLIENT_HQ, JSON.stringify(clientHQ));
  }

  public static getClientHQ(): string {
    let clientHQ = localStorage.getItem(AuthStorage.CLIENT_HQ);
    return clientHQ != null && clientHQ !== 'undefined' ? JSON.parse(clientHQ) : null;
  }

  public static saveChartSelection(isFreezer: boolean, chart: SelectedChart[]): void {
    localStorage.setItem(
      isFreezer ? AuthStorage.CAN_CHART_SELECTION_FREEZER : AuthStorage.CAN_CHART_SELECTION,
      JSON.stringify(chart)
    );
  }

  public static getChartSelection(isFreezer: boolean): SelectedChart[] {
    let chart = localStorage.getItem(isFreezer
      ? AuthStorage.CAN_CHART_SELECTION_FREEZER
      : AuthStorage.CAN_CHART_SELECTION);
    return chart != null && chart !== 'undefined' ? JSON.parse(chart) : null;
  }

}
