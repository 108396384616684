import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ChatService} from '../chat.service';
import {NotificationService} from '../../../notification/notification.service';
import {ChatVehicleListEntry} from '../chat-vehicle-list.entry';
import {Subject} from 'rxjs';
import {DisplayInfoEntry} from '../model/display-info.entry';
import {ChatWsStateService} from '../../../ws/chat-ws-state.service';
import {MessageSortType} from '../model/message-sort.type';
import {AuthStorage} from '../../../auth/auth-storage';
import {takeUntil} from 'rxjs/operators';
import {SubscriptionUtils} from '../../../commons/subscription.utils';
import {ChatMessagesService} from '../messages/chat-messages.service';

@Component({
  selector: 'app-chat-list',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent implements OnInit, OnDestroy {

  @Output()
  public openNewChat: EventEmitter<ChatVehicleListEntry> = new EventEmitter();

  @Output()
  public openDocs: EventEmitter<ChatVehicleListEntry> = new EventEmitter();

  public vehicles: ChatVehicleListEntry[];
  public filteredVehicles: ChatVehicleListEntry[];

  public displayInfo: Map<number, DisplayInfoEntry> = new Map<number, DisplayInfoEntry>();

  private messageSort = AuthStorage.getChatMessageSortConfigs();

  private subSubject = new Subject<boolean>();

  constructor(private chatService: ChatService,
              private chatWsStateService: ChatWsStateService,
              private chatMessagesService: ChatMessagesService,
              private notification: NotificationService) {

  }

  public ngOnInit(): void {
    this.chatService.messageSort$.pipe(takeUntil(this.subSubject)).subscribe({
      next: (sortType) => {
        this.messageSort = sortType;
        if (this.filteredVehicles != null) {
          this.sortVehicles();
        }
      }
    });
    this.chatWsStateService.displayInfo$.pipe(takeUntil(this.subSubject)).subscribe({
      next: (displayInfos) => {
        this.clearAndAdd(displayInfos);
        if (this.filteredVehicles != null) {
          this.sortVehicles();
        }
      }
    });
    this.loadList();
  }

  private clearAndAdd(infos: Map<number, DisplayInfoEntry>) {
    this.displayInfo.clear();
    for (const vehicleId of Object.keys(infos)) {
      const displayInfoEntry = infos[vehicleId];
      this.displayInfo.set(
        +vehicleId,
        displayInfoEntry
      );
    }
  }

  public openChat(vehicle: ChatVehicleListEntry): void {
    this.openNewChat.emit(vehicle);
  }

  public openScannedDocs(vehicle: ChatVehicleListEntry): void {
    this.openDocs.emit(vehicle);
  }

  public filterVehicles($event: any): void {
    if ($event != null) {
      this.filteredVehicles = this.vehicles
        .filter(v => v.plateNumber.toLowerCase().indexOf($event.toLowerCase()) !== -1
          || v.driverName.toLowerCase().indexOf($event.toLowerCase()) !== -1);
    }
    if (this.filteredVehicles != null) {
      this.sortVehicles();
    }
  }

  private loadList(): void {
    this.chatService.getChatVehicleList()
      .pipe(takeUntil(this.subSubject))
      .subscribe({
        next: (data) => {
          this.vehicles = data;
          this.filteredVehicles = data;
          if (this.filteredVehicles.length > 0) {
            this.sortVehicles();
          }
        },
        error: () => this.notification.loadingError()
      });
  }

  private sortVehicles(): void {
    let vehiclesWithMessages = this.filteredVehicles
      .filter(v => this.displayInfo.get(v.id) != null);
    let emptyVehicles =
      this.filteredVehicles.filter(v => this.displayInfo.get(v.id) == null);
    if (vehiclesWithMessages.length > 1) {
      if (MessageSortType.NEW_MESSAGE_AND_SENT_ON === this.messageSort) {
        this.filteredVehicles = this.sortVehiclesByNewMessagesAndTime(vehiclesWithMessages);
      } else if (MessageSortType.SENT_ON === this.messageSort) {
        this.filteredVehicles = vehiclesWithMessages.sort((v1,v2) => this.byLastSentOnSorter(v1, v2));
      } else {
        this.filteredVehicles = this.sortVehiclesWithMessages(vehiclesWithMessages);
      }
    } else {
      this.filteredVehicles = vehiclesWithMessages;
    }
    if (emptyVehicles.length > 1) {
      let items = this.sortEmptyVehicles(emptyVehicles);
      this.filteredVehicles = this.filteredVehicles.concat(items);
    } else {
      this.filteredVehicles = this.filteredVehicles.concat(emptyVehicles);
    }
  }

  private sortEmptyVehicles(vehicles: ChatVehicleListEntry[]): ChatVehicleListEntry[] {
    return vehicles.sort((v1, v2) => {
      if (v1.id > v2.id) {
        return -1;
      } else if (v1.id < v2.id) {
        return 1;
      }
      return 0;
    });
  }

  private sortVehiclesWithMessages(vehicles: ChatVehicleListEntry[]): ChatVehicleListEntry[] {
    let idSorter = (v1, v2) => {
      let message1 = this.displayInfo.get(v1.id).lastMessageId;
      let message2 = this.displayInfo.get(v2.id).lastMessageId;
      if (message1 > message2) {
        return -1;
      } else if (message1 < message2) {
        return 1;
      }
      return 0;
    };
    let high = vehicles
      .filter(it => this.displayInfo.get(it.id).newMessagesCount > 0)
      .sort(idSorter);
    let low = vehicles
      .filter(it => this.displayInfo.get(it.id).newMessagesCount === 0)
      .sort(idSorter);
    return high.concat(low);
  }

  private sortVehiclesByNewMessagesAndTime(vehiclesWithMessages: ChatVehicleListEntry[]): ChatVehicleListEntry[] {
    let withNewMessages = vehiclesWithMessages.filter(it => this.displayInfo.get(it.id).newMessagesCount > 0);
    if (withNewMessages.length > 1) {
      withNewMessages.sort((v1,v2) => this.byLastSentOnSorter(v1, v2));
    }
    let noNewMessages = vehiclesWithMessages.filter(it => this.displayInfo.get(it.id).newMessagesCount === 0);
    if (noNewMessages.length > 1) {
      noNewMessages.sort((v1,v2) => this.byLastSentOnSorter(v1, v2));
    }
    return withNewMessages.concat(noNewMessages);
  }

  private byLastSentOnSorter(v1: ChatVehicleListEntry, v2: ChatVehicleListEntry): number {
    let msg1SentOn = this.displayInfo.get(v1.id).lastSentOn;
    let msg2SentOn = this.displayInfo.get(v2.id).lastSentOn;
    if (msg1SentOn === null && msg2SentOn === null) {
      return 0;
    } else if (msg1SentOn === null) {
      return -1;
    } else if (msg2SentOn === null) {
      return 1;
    }
    return new Date(msg2SentOn).getTime() - new Date(msg1SentOn).getTime();
  }

  public markAllRead(): void {
    this.chatMessagesService.markMessagesAllAsRead()
      .pipe(takeUntil(this.subSubject))
      .subscribe({
        next: () => this.notification.success('chat.list.success'),
        error: () => this.notification.error('chat.list.error')
      });
  }

  public ngOnDestroy(): void {
    SubscriptionUtils.safeUnsubscribeSubject(this.subSubject);
  }

}
